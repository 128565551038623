/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

function ExpeditorsHandlingTab(props) {
  const [totalHandling, setTotalHandling] = useState(0.00);
  const formDetail = props.formDetail;

  useEffect(() => {
    calculateTotalHandling();
  }, [formDetail]);

  const calculateTotalHandling = () => {
    let calculation = 0;
    let noContainer20Gp = formDetail.noContainer20Gp;
    let noContainer20Rf = formDetail.noContainer20Rf;
    let noContainer40Gp = formDetail.noContainer40Gp;
    let noContainer40Hc = formDetail.noContainer40Hc;
    let noContainer40Rf = formDetail.noContainer40Rf;
    let airOrShipFee = formDetail.omManagementFeePerShipmentLclAir;

      calculation = (Number(formDetail.omManagementFeeNoContainer20Gp * noContainer20Gp) + Number(formDetail.omManagementFeeNoContainer20Rf * noContainer20Rf) + Number(formDetail.omManagementFeeNoContainer40Gp * noContainer40Gp) 

    + Number(formDetail.omManagementFeeNoContainer40Hc * noContainer40Hc) + Number(formDetail.omManagementFeeNoContainer40Rf * noContainer40Rf) + Number(formDetail.dthc) + Number(formDetail.othc) + Number(formDetail.other)

    + Number(formDetail.airPermitFee) + Number(formDetail.billOfLadingAmendment) + Number(formDetail.billOfLadingFee) + Number(formDetail.carrierBookingFee) + Number(formDetail.carrierHandlingFee)

    + Number(formDetail.cfsFee) + Number(formDetail.cfsLoading) + Number(formDetail.containerSealFee) + Number(formDetail.courierFee) + Number(formDetail.crossBorderTrucking) + Number(formDetail.customCleranceDeclaration)

    + Number(formDetail.disbursement) + Number(formDetail.documentFee) + Number(formDetail.domesticTrucking) + Number(formDetail.eirHandling) + Number(formDetail.fclDepotHandling) + Number(formDetail.fclDoFee) + Number(formDetail.fclLabourFee)

    + Number(formDetail.fclTruckingReefer) + Number(formDetail.gateFee) + Number(formDetail.gstVat) + Number(formDetail.lclCertificateOfOriginPreparation) + Number(formDetail.lclPortWharfageFclWharfageCharges) + Number(formDetail.lclTerminalHandlingCharge)

    + Number(formDetail.manifestFee) + Number(formDetail.othersFumigation) + Number(formDetail.othersClpningbo) + Number(formDetail.othersCustomPermit) + Number(formDetail.othersCustomExam) + Number(formDetail.othersDetention) + Number(formDetail.othersEdifee)

    + Number(formDetail.othersLashing) + Number(formDetail.othersOvertimeOvernight) + Number(formDetail.palletization) + Number(formDetail.pickUpDrayage) + Number(formDetail.portConstruction) + Number(formDetail.portConstructionPortSecurity) + Number(formDetail.portSecurity)

    + Number(formDetail.specialDocumentHandling) + Number(formDetail.storage) + Number(formDetail.vasLabels) + Number(formDetail.vgmProcessingFee)

    ); 

    if (formDetail.shipModeId == 2 ||  formDetail.shipModeId == 3){
      calculation += Number(airOrShipFee);
    }

    props.handleSetTotalHandling(calculation > 0 ? calculation.toFixed(2) : 0);
  };
  return (
    <>
      <div className="form-body">
        <label>
            Total Handling Cost (USD)
          <input
            disabled
            type='number'
            value={props.totalHandling}
          />
        </label>
      </div>
      <div className='form-body'>
        <label>
              OM Management Fee (Container 20Gp) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'omManagementFeeNoContainer20Gp')}
            type='number'
            value={formDetail.omManagementFeeNoContainer20Gp}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              OM Management Fee (Container 20Rf) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'omManagementFeeNoContainer20Rf')}
            type='number'
            value={formDetail.omManagementFeeNoContainer20Rf}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              OM Management Fee (Container 40Gp) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'omManagementFeeNoContainer40Gp')}
            type='number'
            value={formDetail.omManagementFeeNoContainer40Gp}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              OM Management Fee (Container 40Hc) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'omManagementFeeNoContainer40Hc')}
            type='number'
            value={formDetail.omManagementFeeNoContainer40Hc}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              OM Management Fee (Container 40Rf) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'omManagementFeeNoContainer40Rf')}
            type='number'
            value={formDetail.omManagementFeeNoContainer40Rf}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              OM Management Fee (Per Shipment of LCL and Air) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'omManagementFeePerShipmentLclAir')}
            type='number'
            value={formDetail.omManagementFeePerShipmentLclAir}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              DTHC (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'dthc')}
            type='number'
            value={formDetail.dthc}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
            OTHC (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othc')}
            type='number'
            value={formDetail.othc}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Other (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'other')}
            type='number'
            value={formDetail.other}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             AIR: Permit Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'airPermitFee')}
            type='number'
            value={formDetail.airPermitFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Bill of Lading Amendment (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'billOfLadingAmendment')}
            type='number'
            value={formDetail.billOfLadingAmendment}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Bill of Lading Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'billOfLadingFee')}
            type='number'
            value={formDetail.billOfLadingFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Carrier Booking Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'carrierBookingFee')}
            type='number'
            value={formDetail.carrierBookingFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label> 
             Carrier Handling Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'carrierHandlingFee')}
            type='number'
            value={formDetail.carrierHandlingFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             CFS Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'cfsFee')}
            type='number'
            value={formDetail.cfsFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             CFS Loading (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'cfsLoading')}
            type='number'
            value={formDetail.cfsLoading}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Container Seal Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'containerSealFee')}
            type='number'
            value={formDetail.containerSealFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Courier Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'courierFee')}
            type='number'
            value={formDetail.courierFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Cross Border Trucking (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'crossBorderTrucking')}
            type='number'
            value={formDetail.crossBorderTrucking}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Custom Clearance/Declaration (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'customCleranceDeclaration')}
            type='number'
            value={formDetail.customCleranceDeclaration}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Disbursement (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'disbursement')}
            type='number'
            value={formDetail.disbursement}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Document Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'documentFee')}
            type='number'
            value={formDetail.documentFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Domestic Trucking (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'domesticTrucking')}
            type='number'
            value={formDetail.domesticTrucking}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              EIR Handling (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'eirHandling')}
            type='number'
            value={formDetail.eirHandling}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              FCL: Depot Handling (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclDepotHandling')}
            type='number'
            value={formDetail.fclDepotHandling}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              FCL: DO Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclDoFee')}
            type='number'
            value={formDetail.fclDoFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label> 
              FCL: Labour Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclLabourFee')}
            type='number'
            value={formDetail.fclLabourFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: Trucking (Reefer) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclTruckingReefer')}
            type='number'
            value={formDetail.fclTruckingReefer}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Gate Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'gateFee')}
            type='number'
            value={formDetail.gateFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              GST/VAT (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'gstVat')}
            type='number'
            value={formDetail.gstVat}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              LCL: Certificate of Origin Preparation (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'lclCertificateOfOriginPreparation')}
            type='number'
            value={formDetail.lclCertificateOfOriginPreparation}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             LCL: Port Wharfage / FCL: Wharfage Charges (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'lclPortWharfageFclWharfageCharges')}
            type='number'
            value={formDetail.lclPortWharfageFclWharfageCharges}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              LCL: Terminal Handling Charge (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'lclTerminalHandlingCharge')}
            type='number'
            value={formDetail.lclTerminalHandlingCharge}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Manifest Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'manifestFee')}
            type='number'
            value={formDetail.manifestFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Others - Fumigation (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othersFumigation')}
            type='number'
            value={formDetail.othersFumigation}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Others-CLP  (Ningbo) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othersClpningbo')}
            type='number'
            value={formDetail.othersClpningbo}
            disabled={props.orderIsChild}
          />
        </label>   
        <label>
             Others-Custom Permit (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othersCustomPermit')}
            type='number'
            value={formDetail.othersCustomPermit}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Others-Custom Exam (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othersCustomExam')}
            type='number'
            value={formDetail.othersCustomExam}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Others-Detention (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othersDetention')}
            type='number'
            value={formDetail.othersDetention}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Others-EDI Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othersEdifee')}
            type='number'
            value={formDetail.othersEdifee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Others-Lashing (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othersLashing')}
            type='number'
            value={formDetail.othersLashing}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Others-Overtime/Overnight (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othersOvertimeOvernight')}
            type='number'
            value={formDetail.othersOvertimeOvernight}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Palletization (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'palletization')}
            type='number'
            value={formDetail.palletization}
            disabled={props.orderIsChild}
          />
        </label>
        <label> 
              Pick up / Drayage (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'pickUpDrayage')}
            type='number'
            value={formDetail.pickUpDrayage}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Port Construction (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'portConstruction')}
            type='number'
            value={formDetail.portConstruction}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Port Construction / Port Security (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'portConstructionPortSecurity')}
            type='number'
            value={formDetail.portConstructionPortSecurity}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Port Security (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'portSecurity')}
            type='number'
            value={formDetail.portSecurity}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Special Document Handling (USD)
          <input 
            onChange={e => props.handleTextInputUpdate(e, 'specialDocumentHandling')}
            type='number'
            value={formDetail.specialDocumentHandling}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              Storage (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'storage')}
            type='number'
            value={formDetail.storage}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             VAS Labels (For MBU which requires VAS) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'vasLabels')}
            type='number'
            value={formDetail.vasLabels}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             VGM Processing Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'vgmProcessingFee')}
            type='number'
            value={formDetail.vgmProcessingFee}
            disabled={props.orderIsChild}
          />
        </label>
      </div>
    </>
  );
}

export default ExpeditorsHandlingTab;
