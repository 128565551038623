import {
  PUSHNAVIGATION_REQUEST,
  REPLACENAVIGATION_REQUEST,
  BACKNAVIGATION_REQUEST,
  RESETNAVIGATION_REQUEST,
} from '../actions/actionTypes';
import initialState from './initialState';

const loadStateFromLocalStorage = () => {
  const savedState = localStorage.getItem('navigationState');
  return savedState ? JSON.parse(savedState) : initialState.navigationState;
};

export const navigationReducer = (state = loadStateFromLocalStorage(), action) => {
  switch (action.type) {
  case PUSHNAVIGATION_REQUEST: {
    const newState = {
      history: [...state.history, action.payload.url],
      navigationCount: state.navigationCount + 1,
      backInProgress: false,
    };
    localStorage.setItem('navigationState', JSON.stringify(newState));
    return newState;
  }
  case REPLACENAVIGATION_REQUEST: {
    if (state.history.length > 0) state.history.pop();

    const newState = {
      history: [...state.history, action.payload.url],
      navigationCount: state.navigationCount + 1,
      backInProgress: false,
    };
    localStorage.setItem('navigationState', JSON.stringify(newState));
    return newState;
  }
  case BACKNAVIGATION_REQUEST: {
    if (state.backInProgress) {
      return state;
    }

    const newState = {
      ...state,
      backInProgress: true,
    };
    localStorage.setItem('navigationState', JSON.stringify(newState));
    setTimeout(() => {
      if (newState.history.length > 0) {
        newState.history.pop();
      }

      newState.backInProgress = false;
      localStorage.setItem('navigationState', JSON.stringify(newState));
    }, 1500);

    return newState;
  }
  case RESETNAVIGATION_REQUEST: {
    const newState = {
      ...state,
      history: [],
      navigationCount: state.navigationCount + 1,
      backInProgress: false,
    };
    localStorage.setItem('navigationState', JSON.stringify(newState));
    return newState;
  }
  default:
    return state;
  }
};

export default navigationReducer;
