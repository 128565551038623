import {
  APAC_ORDERTRACKER_CLEARSTATE,
  APAC_ORDERTRACKER_SHIPMENT_REQUEST,
  APAC_ORDERTRACKER_SHIPMENT_SUCCESS,
  APAC_ORDERTRACKER_SHIPMENT_FAILURE,
  APAC_SHIPMENTLOGUPLOAD_REQUEST,
  APAC_SHIPMENTLOGUPLOAD_SUCCESS,
  APAC_SHIPMENTLOGUPLOAD_FAILURE,
  APAC_SAVEORDER_REQUEST,
  APAC_SAVEORDER_SUCCESS,
  APAC_SAVEORDER_FAILURE,
  APAC_ORDERTRACKER_GETORDERS_REQUEST,
  APAC_ORDERTRACKER_GETORDERS_SUCCESS,
  APAC_ORDERTRACKER_GETORDERS_FAILURE,
  APAC_ORDERTRACKER_EXPORTORDERS_REQUEST,
  APAC_ORDERTRACKER_EXPORTORDERS_SUCCESS,
  APAC_ORDERTRACKER_EXPORTORDERS_FAILURE,
  APAC_ORDERTRACKER_INPROGRESSSUMMARY_REQUEST,
  APAC_ORDERTRACKER_INPROGRESSSUMMARY_SUCCESS,
  APAC_ORDERTRACKER_INPROGRESSSUMMARY_FAILURE,
  APAC_BULKORDERSTATUSUPDATE_REQUEST,
  APAC_BULKORDERSTATUSUPDATE_SUCCESS,
  APAC_BULKORDERSTATUSUPDATE_FAILURE,
} from './actionTypes';

const apacOrderTrackerActions = {
  clearState: () => {
    return {
      type: APAC_ORDERTRACKER_CLEARSTATE,
    };
  },
  shipmentLogUpload: (uploadFile) => (dispatch) => {
    dispatch({
      type: APAC_SHIPMENTLOGUPLOAD_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/omshipment/v1/shipmentlogupload',
        successAction: APAC_SHIPMENTLOGUPLOAD_SUCCESS,
        failureAction: APAC_SHIPMENTLOGUPLOAD_FAILURE,
        body: {
          ...uploadFile,
        },
      },
    });
  },
  getShipmentDetail: (payload) => (dispatch) => {
    dispatch({
      type: APAC_ORDERTRACKER_SHIPMENT_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/omshipment/v1/shipmentdetail?trackingNumber=${payload}`,
        successAction: APAC_ORDERTRACKER_SHIPMENT_SUCCESS,
        failureAction: APAC_ORDERTRACKER_SHIPMENT_FAILURE,
      },
    });
  },
  saveShipmentDetail: (payload) => (dispatch) => {
    dispatch({
      type: APAC_SAVEORDER_REQUEST,
      http: {
        verb: 'PUT',
        endpoint: '/api/omshipment/v1/saveshipmentdetail',
        successAction: APAC_SAVEORDER_SUCCESS,
        failureAction: APAC_SAVEORDER_FAILURE,
        body: payload,
      },
    });
  },
  getInProgressSummary: (filter, page, pageSize) => (dispatch) => {
    dispatch({
      type: APAC_ORDERTRACKER_INPROGRESSSUMMARY_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/omorder/v1/getInProgressSummary${convertToQuerystring(filter, page, pageSize)}`,
        successAction: APAC_ORDERTRACKER_INPROGRESSSUMMARY_SUCCESS,
        failureAction: APAC_ORDERTRACKER_INPROGRESSSUMMARY_FAILURE,
      },
    });
  },
  exportInProgressSummary: (filter) => (dispatch) => {
    dispatch({
      type: APAC_ORDERTRACKER_EXPORTORDERS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/omorder/v1/exportInProgressSummary${convertToQuerystring(filter)}`,
        successAction: APAC_ORDERTRACKER_EXPORTORDERS_SUCCESS,
        failureAction: APAC_ORDERTRACKER_EXPORTORDERS_FAILURE,
      },
    });
  },
  bulkOrderStatusUpdate: (request) => (dispatch) => {
    dispatch({
      type: APAC_BULKORDERSTATUSUPDATE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/omorder/v1/bulkOrderStatusUpdate',
        successAction: APAC_BULKORDERSTATUSUPDATE_SUCCESS,
        failureAction: APAC_BULKORDERSTATUSUPDATE_FAILURE,
        body: request,
      },
    });
  },
  getApacOrders: (filter, page, pageSize) => (dispatch) => {
    dispatch({
      type: APAC_ORDERTRACKER_GETORDERS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/omorder/v1/getOrders${convertToQuerystring(filter, page, pageSize)}`,
        successAction: APAC_ORDERTRACKER_GETORDERS_SUCCESS,
        failureAction: APAC_ORDERTRACKER_GETORDERS_FAILURE,
      },
    });
  },
  exportApacOrders: (filter) => (dispatch) => {
    dispatch({
      type: APAC_ORDERTRACKER_EXPORTORDERS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/omorder/v1/exportOrders${convertToQuerystring(filter)}`,
        successAction: APAC_ORDERTRACKER_EXPORTORDERS_SUCCESS,
        failureAction: APAC_ORDERTRACKER_EXPORTORDERS_FAILURE,
      },
    });
  },
};

const convertToQuerystring = (obj) => {
  const qs = Object.keys(obj)
    .filter(key => obj[key] !== undefined)
    .map(key => 
      key =='selectedMonths' && obj.selectedMonths ? 
        obj.selectedMonths.map((i) => `${key}=${i}`).join('&'):
        key =='selectedYears' && obj.selectedYears ? 
          obj.selectedYears.map((i) => `${key}=${i}`).join('&'):
          `${key}=${obj[key]}`)
    .join('&');
    
  return qs.length === 0
    ? qs
    : `?${qs}`;
};

export default apacOrderTrackerActions;
