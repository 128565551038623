import React, { useEffect, useState  } from 'react';
import { connect } from 'react-redux';
import '../ordertracker/SearchOrders/SearchOrders.scss';
import Pagination from './Pagination';
import { navigationActions } from '../../actions/navigationActions';
import MultiSelect from '../siteID/siteIDApplications/list/MultiSelect';

const SelectChildOrdersModal = props => {

  const [isClosed, setIsClosed] = useState(window.location.toString().toLocaleLowerCase().indexOf('close') > -1);
  const [isAdmin, setIsAdmin] = useState(window.location.toString().toLocaleLowerCase().indexOf('admin') > -1);
  const [isLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [rowCount, setRowCount] = useState(0);

  
  const [filterForms, setFilterForms] = useState({ 
    orderTypeId: undefined,
    shipmentTypeId: undefined,
    orderStatusId: undefined,
    itemNumber: undefined,
    supplierName: undefined,
    trackingNumber: undefined });
  const [forms, setForms] = useState({});
  const [pageRendered, setPageRendered] = useState(false);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [children, setChildren] = useState([]);
  const [marketId, setMarketId] = useState(props.marketId);
  const [orderId, setOrderId] = useState(props.orderId);
  const [customerNumber, setcustomerNumber] = useState(props.customerNumber);
  const [loading, setLoading] = useState(false);
 
 
  const parent = props.parentTrackingNumber;
  const orderStatuse = props.orderStatuses.filter(x=>x.key!=6); 

  useEffect(() => {
    async function changePage() {
      await handleFilterWithParameters({ ...filterForms, selectedMonths, selectedYears, isAdmin, isClosed, page, pageSize, marketId, orderId, customerNumber });
      setPageRendered(true);
    }
    changePage();
  }, [page]);

  const convertToQuerystring = (children, parent,marketId) => {
    const params = new URLSearchParams();

    if (Array.isArray(children) && children.length > 0) {
      const intChildren = children.map(num => parseInt(num, 10)).join(',');
      params.append('children', intChildren); // Append the converted list
    }

    if (parent) {
      params.append('parent', parent);
    }

    if (marketId>0) {
      params.append('marketId', marketId);
    }

    return params.toString() ? `?${params.toString()}` : '';
  };

  const saveChildren = async(params) => {

    const response = await fetch(`/api/omshipment/v1/saveNewConsolidatedChildren${convertToQuerystring(children, parent, marketId)}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    if (response.status == 200){
   
      props.cancel();
      window.location.reload();
    }    
  };

  const getForms = async(params) => {
    const convertToQuerystring = (obj) => {
      const queryParts = [];

      Object.keys(obj).forEach(key => {
        if (obj[key] !== undefined) {
          if (key === 'selectedMonths' && obj.selectedMonths) {
            obj.selectedMonths.forEach(i => queryParts.push(`${key}=${i}`));
          } else if (key === 'selectedYears' && obj.selectedYears) {
            obj.selectedYears.forEach(i => queryParts.push(`${key}=${i}`));
          } else {
            queryParts.push(`${key}=${obj[key]}`);
          }
        }
      });

      const qs = queryParts.join('&');
      return qs.length === 0 ? '' : `?${qs}`;
    };
      
    const response = await fetch(`/api/omshipment/v1/notConsolidatedOrders${convertToQuerystring(params, page, pageSize)}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    if (response.status == 200){
      const data = await response.json();
      setForms(data.forms.data);
      setPageSize(data.forms.pageSize);
      setPage(data.forms.pageNumber);
      setRowCount(data.forms.totalRecords);
      setIsAdmin(data.isAdmin);
      setMarketId(props.marketId);
      setOrderId(props.orderId);
      setLoading(false);
      setcustomerNumber(props.customerNumber);

    }
  };

  const handleFilterWithParameters = async(params) => {
    const pathname = window.location.pathname;
    const filterString = `orderStatusId=${filterForms.orderStatusId}` +
        `&marketId=${marketId}` +
        `&itemNumber=${filterForms.itemNumber}` +
        `&shipmentTypeId=${filterForms.shipmentTypeId}` +
        `&supplierName=${filterForms.supplierName}` +
        `&selectedMonths=${params.selectedMonths}` +
        `&selectedYears=${params.selectedYears}` +
        `&orderTypeId=${filterForms.orderTypeId}` +
        `&orderId=${props.orderId}` +
        `&customerNumber=${customerNumber}` +
        `&trackingNumber=${filterForms.trackingNumber}`
        ;

    props.replaceNavigation(`${pathname}?page=${params.page}&${filterString}`);
    
    await getForms(params);
  };
 
  const handleFilter = async() => {
    const page = 1;
    setPage(page);
    await handleFilterWithParameters({ ...filterForms, selectedMonths, selectedYears, isAdmin, isClosed, page, pageSize, marketId, orderId, customerNumber });
  };

  const handleFilterClear = async() => {
    const newFilters = {
      orderTypeId: '',
      orderStatusId: '',
      shipmentTypeId: '',
      itemNumber: '',
      supplierName: '',
      trackingNumber:'',
    };
    const page = 1;
    setPage(1);
    setFilterForms({ ...newFilters });
    setSelectedMonths([]);
    setSelectedYears([]);

    await handleFilterWithParameters({ ...newFilters, selectedMonths: [], selectedYears: [], isAdmin, isClosed, page, pageSize , marketId, orderId,customerNumber });
  };

  const handleFilterInputUpdate = (e, fieldName) => {
    setFilterForms({ ...filterForms, [fieldName] : e.target.value });
  };

  const doPaging = async(newPage) => {
    if (loading) return;
    setLoading(true);
    setPage(newPage);
  };

  useEffect(() => {
  }, [filterForms]);

  const doToggleMonthsMulti = (selectedValues) => {
    setSelectedMonths(selectedValues);
  };

  const doToggleYearsMulti = (selectedValues) => {
    setSelectedYears(selectedValues);
  };

  const handleChildSelect =(id) => (e) => {
   
    const isChecked = e.target.checked;

    if (isChecked) {
      setChildren(prev => [...prev, id]);
    } else {
      // Remove tracking number if unchecked
      setChildren(prev => prev.filter(num => num !== id));
    }
  };

  const months = [
    { key: 1, value: 'January' },
    { key: 2, value: 'February' },
    { key: 3, value: 'March' },
    { key: 4, value: 'April' },
    { key: 5, value: 'May' },
    { key: 6, value: 'June' },
    { key: 7, value: 'July' },
    { key: 8, value: 'August' },
    { key: 9, value: 'September' },
    { key: 10, value: 'October' },
    { key: 11, value: 'November' },
    { key: 12, value: 'December' },
  ];

  const orderTypes=[
    { key: 1, value: 'Ambient' },
    { key: 2, value: 'Autoship Ambient' },
    { key: 6, value: 'Direct Shipment Ambient' },
    { key: 7, value: 'Promotional' },
    { key: 9, value: 'Store Development' },
    { key: 13, value: 'Reefer' },
    { key: 14, value: 'Direct Shipment Reefer' },
    { key: 15, value: 'Autoship Reefer' },
    { key: 16, value: 'Direct Shipment Store Development' },
    { key: 24, value: 'Other' }];

  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 1;

  const availableTimePeriods = [
    { key: currentYear.toString(), value: currentYear.toString() }, { key: startYear.toString(), value: startYear.toString() },
  ];

  return (
    <div>
      {pageRendered && (
        <React.Fragment>
          <div className='page'>
            <div className="modal-blocker">
              <div className="modal"
                style={{
                  position: 'fixed',
                  zIndex: 1000,
                  maxWidth: '95%',
                  maxHeight:'95%',
                  overflowY: 'auto',
                }}>
                <form>
                  <div className="modal-menu">
                    <button
                      type="button"
                      className="action"
                      onClick={() => {
                        props.cancel();
                      }}
                    >
              X
                    </button>
                  </div>
                  <div className="form-header apac">
                    <div className="header-title">
              
                      {/* <h2>Select Child Orders</h2> */}
                    </div>
                  </div>
       
                  <div className='floating-panel form-container'>
                    <div> 
                      <div className='form-body'>
                        <label>
                  Tracking Number
                          <input
                            type="text"
                            placeholder="Tracking Number"
                            onChange={e => handleFilterInputUpdate(e, 'trackingNumber')}
                            value={filterForms.trackingNumber}
                          />
                        </label>
                        <label>
                    Shipment Type
                          <select
                            value={filterForms.shipmentTypeId}
                            onChange={e => handleFilterInputUpdate(e, 'shipmentTypeId')}
                          >
                            <option value=''>Select a shipment type</option>
                            {props.shipmentTypes && props.shipmentTypes.length && props.shipmentTypes.map(m => 
                              <option key={m.key}
                                value={m.key}>{m.value}</option>,
                            )
                            }
                          </select>
                        </label>
                        <label>
                  Order Type
                          <select
                            value={filterForms.orderTypeId}
                            onChange={e => handleFilterInputUpdate(e, 'orderTypeId')}>
                            <option value=''>Select an Order Type</option>
                            {orderTypes && props.orderTypes.length && props.orderTypes.map(m => 
                              <option key={m.key}
                                value={m.key}>{m.value}</option>,
                            )
                            }
                          </select>
                        </label>
                        <label>
                  Item Number
                          <input
                            type="text"
                            placeholder="Item Number"
                            onChange={e => handleFilterInputUpdate(e, 'itemNumber')}
                            value={filterForms.itemNumber}
                          />
                        </label>
                        <label>
                  Supplier Name
                          <input
                            type="text"
                            placeholder="Supplier Name"
                            onChange={e => handleFilterInputUpdate(e, 'supplierName')}
                            value={filterForms.supplierName}
                          />
                        </label>
              
                        <MultiSelect
                          title="Months"
                          selectedValues={selectedMonths}
                          valueChanged={doToggleMonthsMulti}
                          values={months}
                          valueName="selectedMonths"
                        />
                        <MultiSelect
                          title="Years"
                          selectedValues={selectedYears}
                          valueChanged={doToggleYearsMulti}
                          values={availableTimePeriods}
                          valueName="selectedYears"
                        />   
                        <label>
                   Status
                          <select
                            value={filterForms.orderStatusId}
                            onChange={e => handleFilterInputUpdate(e, 'orderStatusId')}
                          >
                            <option value=''>Select a Status</option>
                            {orderStatuse && orderStatuse.length && orderStatuse.map(m => 
                              <option key={m.key}
                                value={m.key}>{m.value}</option>,
                            )
                            }
                          </select>
                        </label>
      

                      </div>
                    </div>
    
                    <div
                      style={{ paddingBottom:'px', display:'flex', justifyContent:'flex-end' }}
                    >
                      <button onClick={() => handleFilter()}
                        type='button'
                        style={{ marginRight:'18px' }}
                        className='action'>
                    Filter
                      </button>
                      <button onClick={() => handleFilterClear()}
                        type='button'
                        className='action negative'>
                    Clear Filter
                      </button>
            
                    </div>

                  </div>
        
                  <div className={'customer-ordertracker-container in-progress'}>
                    <div className="table-container">
                      <div className="table-header">
             
                        <h2>
            Search Results
                        </h2>
                        <div className="pagination-controls">
                          <Pagination
                            rowCount={rowCount}
                            page={page}
                            pageSize={pageSize}
                            doPaging={doPaging}
                            isLoading={isLoading}
                          />
              
                        </div>

                      </div>
                      <div className="table-body">
                        <table className={'table-display-mode-3'}>
                          <thead>
                            <tr>
                              <th>Tracking Number</th>
                              <th>Starbucks Order Number</th>
                              <th>Order Type</th>
                              <th>Order Status</th>
                              <th>Days Delayed</th>
                              <th>Order Date</th>
                              <th>Shipment Consolidation</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!forms || forms.length == 0 &&
                <tr>
                  <td
                    colSpan={9}
                    className="no-records"
                    style={{ textAlign: 'center' } }
                  >
                    No orders available.
                  </td>
                </tr>
                            }
                            {forms && forms.length > 0 && forms.map(o => 
                              <tr key={o.id}>
                                <td>{o.trackingNumber}</td>
                                <td>{o.sbOrderNumber}</td>
                                <td>{props.orderTypes.find(m => m.key == o.orderTypeId)?.value ?? o.orderTypeId}</td>
                                <td>{props.orderStatuses.find(m => m.key == o.orderStatusId)?.value ?? o.orderStatusId}</td>
                                <td>{o.daysDelayed ?? 0}</td>
                                <td>{o.orderDate && new Date(o.orderDate).toLocaleDateString()}</td>
                                <td>
                   
                                  <input
                                    type="checkbox"
                                    // checked={o.}
                                    //disabled={!canEdit || !orderIsParent}
                                    onChange={ handleChildSelect(o.id)}
                                  />
                    
                 
                                </td>
                              </tr>,
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div  style={{ paddingBottom:'18px', paddingTop:'18px', display:'flex', justifyContent:'flex-end' }}>
                    <button onClick={() => saveChildren()} 
                      type='button'
                      className='action'>
                    Save
                    </button>
                  </div>
           
                </form>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};


const mapStateToProps = () => {
  return {  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pushNavigation: (url) => dispatch(navigationActions.pushNavigation(url)),
    replaceNavigation: (url) => dispatch(navigationActions.replaceNavigation(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectChildOrdersModal);