import React from 'react';
import { connect } from 'react-redux';
import ProgressState from '../components/ProgressState/ProgressState';
import './ShippingDetails.scss';
import apacOrderTrackerActions from '../../../actions/apacOrderTrackerActions';
import { navigationActions } from '../../../actions/navigationActions';
import OrderAudit from '../OrderAudit/OrderAudit';
import SelectChildOrdersModal from '../../shared/SearchChildOrdersModal';
import Select from 'react-select';

class ShippingDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: {},
      orderType: '',
      orderIsParent: false,
      orderIsChild: false,
      orderCanParent: true,
      showSelectChildOrdersModal:false,
      marketId: 0,
    };

    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);
    this.handleChildInput = this.handleChildInput.bind(this);
    this.handleParentInput = this.handleParentInput.bind(this);
    this.saveShipmentDetail = this.saveShipmentDetail.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  componentDidMount(){
    const { match } = this.props;
    let id = match.params.trackingNumber;
    this.props.getOrder(id);
  }
   
  componentDidUpdate(prevProps){
    if(this.props.isLoaded && !prevProps.isLoaded){
      this.setState({
        order: this.props.orders,
        orderType: this.props.orderTypes.find(o => o.key == this.props.orders.orderTypeId)?.value,
        shipmentType: this.props.shipmentTypes.find(s => s.key == this.props.orders.shipmentTypeId)?.value,
      });
      
      this.setState({
        orderIsParent: (this.props.childOrders?.length) ? true : false,
        orderIsChild: (this.props.orders.removeFromConsolidatedParent==true) ? false : this.props.orders.trackingNumberOfParentOrder?.length>=10,
        orderCanParent: (this.props.orders.trackingNumberOfParentOrder=='' || this.props.childOrders?.length>0) ? true : false,
      });
    }
    if (this.props.isSaved && !prevProps.isSaved) {
      this.props.back();
    }
  }

  handleModal(modalName, show) {
    this.setState({ [modalName]: show });
  }



  saveShipmentDetail() {
    const { order } = this.state;
    const orderIsChild = this.state.orderIsChild;
    const orderIsParent = this.state.orderIsParent;
    let data = {
      TrackingNumber: order.trackingNumber,
      PromiseDate: order.promiseDate,
      PortOfLoading: order.portOfLoading,
      Region: order.region,
      ShipmentMethod: order.shipMethod,
      Carrier: order.carrier,
      PortOfDischarge: order.portOfDischarge,
      PlaceOfDelivery: order.placeOfDelivery,
      FtaFormReceiptDate: order.ftaFormReceiptDate,
      TemperatureControlConditionId: order.temperatureControlConditionId,
      ContainerNumber: order.containerNumbers,
      Masterbill: order.masterBill,
      Etd: order.etd,
      Cob: order.cob,
      Eta: order.eta,
      OrderStatusId: order.orderStatusId,
      Comments: order.comments,
      ShipmentComments: order.shipmentComments,
      RtsDate: order.rtsDate,
      ShipmentsSplit: order.shipmentsSplit,
      ShipmentsSplitComments: order.shipmentsSplitComments,
      ShipmentsConsolidated: orderIsParent,
      ShipmentsConsolidatedComments: order.shipmentsConsolidatedComments,
      OrderTypeId: order.orderTypeId,
      ShipmentTypeId: order.shipmentTypeId,
      LtoOrder: order.ltoOrder === '1',
      _3pl: order._3plId,
      RemoveFromConsolidatedParent: !orderIsChild,
    };

    this.props.saveOrder(data);
  }
  forceCloseShipment() {
    const { order } = this.state;
    
  
    let data = {
      TrackingNumber: order.trackingNumber,
      PromiseDate: order.promiseDate,
      PortOfLoading: order.portOfLoading,
      Region: order.region,
      ShipmentMethod: order.shipMethod,
      Carrier: order.carrier,
      PortOfDischarge: order.portOfDischarge,
      PlaceOfDelivery: order.placeOfDelivery,
      FtaFormReceiptDate: order.ftaFormReceiptDate,
      TemperatureControlConditionId: order.temperatureControlConditionId,
      ContainerNumber: order.containerNumbers,
      Masterbill: order.masterBill,
      Etd: order.etd,
      Cob: order.cob,
      Eta: order.eta,
      OrderStatusId: 6,
      Comments: order.comments,
      RtsDate: order.rtsDate,
      ShipmentsSplit: order.shipmentsSplit,
      ShipmentComments: order.shipmentComments,
      ShipmentsSplitComments: order.shipmentsSplitComments,
      ShipmentsConsolidated: order.shipmentsConsolidated,
      ShipmentsConsolidatedComments: order.shipmentsConsolidatedComments,
      OrderTypeId: order.orderTypeId,
      ShipmentTypeId: order.shipmentTypeId,
      LtoOrder: order.ltoOrder === '1',
      _3pl: order._3plId,
      forceClose: true,
    };

    this.props.saveOrder(data);
  }

  handleTextInputUpdate(e, fieldName) {
    this.setState({ 
      order: { ...this.state.order, [fieldName]: e.target.value },
    });
  }



  
  handleCheckInputUpdate(e, fieldName) {
    this.setState({ 
      order: { ...this.state.order, [fieldName]: e.target.checked },
    });
  }

  handleParentInput(e, fieldName){
    this.setState({ orderIsParent:  e.target.checked });
    if(e.target.checked==true)  this.setState({ orderCanParent:  e.target.checked });  this.setState({ orderIsParent:  e.target.checked });
       
  }

  handleChildInput(e, fieldName){
    this.setState({ fieldName:  e.target.checked });
    this.setState({ orderIsChild:  e.target.checked });
  }

  getMappedFulfillmentCenter(orgCode) {
    switch (orgCode){
    case 'FCS':
      return 'Singapore';
    case 'F22':
      return 'Auburn';
    case 'F26':
      return 'Carson Valley';
    case 'F27':
      return 'York';
    case 'PHM':
      return 'HACO Malaysia';
    case 'PDM':
      return 'Dan Kaffe Malaysia Sdn Bhd';
    case 'FCT':
      return 'Japan Roastery';
    case 'PHJ':
      return 'Hamaya Japan';
    case 'ZDF':
      return 'Japan Local Roasting';
    case 'ZD5':
      return 'Direct Shipment Supplier';
    case 'F81':
      return 'Futian Store Development China';
    }

    return '';
  }

  render() {
   
    const {
      isSaved,
      isSaving,
      errorMessage,
      tempControls,
      orderStatuses,
      orderTypes,
      childOrders,
      shipmentTypes,
      isAdmin,
      canEdit,
      recievedRequiredDocumentTypes,
      requiredDocumentTypes,
      documentTypes,
      mbu,
    } = this.props;

    const { order } = this.state;
    const orderIsChild = this.state.orderIsChild;
    const orderIsParent = this.state.orderIsParent;
    const orderCanParent = this.state.orderCanParent;
    const options = Array.isArray(childOrders) && childOrders.length > 0
      ? childOrders.map(order => ({
        value: order.key,
        label: order.value,
      })) 
      : null;
    const defaultValue = Array.isArray(options) && options.length > 0 ? options[0] : null;
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        borderRadius: '21px', 
        border: state.isFocused ? '1.9 px solid green' : '1px solid #ccc', 
        boxShadow: 'none', 
        '&:hover': {
          borderColor: state.isFocused ? 'green' : '#aaa', 
        },
        outline: 'none',
        height: '40px', 
        minHeight: '40px',  
        fontSize: '16px', 
        lineHeight: '40px', 
        marginBottom: '10px',
        paddingBottom: '8% 3%',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
   
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        paddingRight: '10px',
        height: '36px', 
        alignSelf: 'center',
        marginTop: '-3%',
      }),
      singleValue: (provided) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center', 
        height: '100%', 
        marginTop: '-3%',
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none', 
      }),
      menu: (provided) => ({
        ...provided,
      }),
      option: (provided) => ({
        ...provided,
        fontSize: '14px',
        height: '40px', 
        minHeight: '40px',
      }),
    };
    let isLAC = this.props.tenant == 'LAC';
    let isDirectShipOrder = order.orderTypeId === 6 || order.orderTypeId === 16 ? true : false;
 

    return (
      <div className="shipping-details page">
        <div className="form-container">
          <div className="form-header">
            <div className="header-title">
              <div className="icon icon-form"></div>
              <h2>Shipping Details</h2>
            </div>
            <div className="header-buttons">
              
              <button className="action"
                type="button"
                onClick={() => this.saveShipmentDetail()}>
                {isSaving
                  ? 'Saving...'
                  : isSaved
                    ? 'Saved'
                    : 'Save'}
              </button>
              <p>{errorMessage}</p>
            </div>
          </div>
        </div>
        {order &&
          <>
            <div 
              className="form-container">
              <h4 className="header-shipping">Shipping Details</h4>
         
              <div className="form-body">
                <label>
              Shipment Type
                  <select
                    value={order.shipmentTypeId}
                    disabled={!canEdit}
                    onChange={e => this.handleTextInputUpdate(e, 'shipmentTypeId')}
                  >
                    <option value=''>N/A</option>
                    {shipmentTypes && shipmentTypes.length > 0 && shipmentTypes.map(t => 
                      <option key={t.key} 
                        value={t.key}>{t.value}</option>,
                    )}
                  </select>
                </label>
                <label>
              3PL
                  <select
                    value={order._3plId}
                    disabled={isLAC || !isAdmin}
                    onChange={e => this.handleTextInputUpdate(e, '_3plId')}
                  >
                    <option value=''>N/A</option>
                    <option value='1'>Expeditors</option>
                    <option value='2'>DSV</option>
                    <option value='3'>Nissin</option>
                    <option value='4'>YCH</option>
                    <option value='5'>{isLAC ? 'DHL' : 'YCH-FRDC'}</option>
                    <option value='999'>Other</option>
                  </select>
                </label>
              </div>
              <div className="form-body">          
                <label>
            MBU
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={mbu}
                  />
                </label>
                <label>
            Customer Number
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.customerNumber}
                  />
                </label>
                <label>
            Forecast Week
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.forecastWeek}
                  />
                </label>
                <label>
            Order Date
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.orderDate ? new Date(order.orderDate).toLocaleDateString() : null}
                  />
                </label>
                <label>
            Order Type
                  <select
                    value={order.orderTypeId}
                    disabled={!canEdit || order.shipmentTypeId != 1}
                    onChange={e => this.handleTextInputUpdate(e, 'orderTypeId')}
                  >
                    <option value=''>N/A</option>
                    {orderTypes && orderTypes.length > 0 && orderTypes.map(t => 
                      <option key={t.key} 
                        value={t.key}>{t.value}</option>,
                    )}
                  </select>
                </label>
              </div>
            </div>
            
            <div className="form-container">
              <h4 className="header-shipping">Tracking Details</h4>

              <div className="form-body">
                <label>
            Starbucks Order Number
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.starbucksOrderNumber}
                  />
                </label>
                <label>
           Delivery ID
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.deliveryId}
                  />
                </label>
                <label>
            ORG
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.org}
                  />
                </label>
                {order.org ? <label>
           Fulfilment Centre
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    hidden
                    value={this.getMappedFulfillmentCenter(order.org)}
                  />
                </label> : null}
                <label>
            ISO Destination ORG
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.isoDestinationOrg}
                  />
                </label>
                <label>
            Tracking Number
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.trackingNumber}
                  />
                </label> 
                <label>
           Supplier PO/Release No
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!isDirectShipOrder}
                    value={order.supplierReleaseNo}
                  />
                </label>
                <label>
           RTS
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.rtsDate ? new Date(order.rtsDate).toLocaleDateString() : null}
                  />
                </label>
                <label>
           Promise Date
                  <input
                    autoFocus
                    type="date"
                    required
                    disabled={!canEdit}
                    value={order.promiseDate && order.promiseDate.split('T')[0]}
                    onChange={e => this.handleTextInputUpdate(e, 'promiseDate')}
                  />
                </label>
                <label>
            Port Of Loading
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.portOfLoading}
                    onChange={e => this.handleTextInputUpdate(e, 'portOfLoading')}
                  />
                </label>
                <label>
           Region
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.region}
                    onChange={e => this.handleTextInputUpdate(e, 'region')}
                  />
                </label>
                <label>
            Ship Method
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.shipMethod}
                    onChange={e => this.handleTextInputUpdate(e, 'shipMethod')}
                  />
                </label>
                <label>
            Carrier
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.carrier}
                    onChange={e => this.handleTextInputUpdate(e, 'carrier')}
                  />
                </label>
                <label>
            Port Of Discharge
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.portOfDischarge}
                    onChange={e => this.handleTextInputUpdate(e, 'portOfDischarge')}
                  />
                </label>
                <label>
            Place Of Delivery
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.placeOfDelivery}
                    onChange={e => this.handleTextInputUpdate(e, 'placeOfDelivery')}
                  />
                </label>
                <label>
           FTA Form Receipt Date
                  <input
                    autoFocus
                    type="date"
                    required
                    disabled={!canEdit}
                    value={order.ftaFormReceiptDate && order.ftaFormReceiptDate.split('T')[0]}
                    onChange={e => this.handleTextInputUpdate(e, 'ftaFormReceiptDate')}
                  />
                </label>
                <label>
           Temperature Control Condition
                  <select         
                    disabled={!canEdit}
                    value={order.temperatureControlConditionId} 
                    onChange={e => this.handleTextInputUpdate(e, 'temperatureControlConditionId')}>
                    <option value=''>N/A</option>
                    {tempControls && tempControls.length > 0 && tempControls.map(t => 
                      <option key={t.key} 
                        value={t.key}>{t.value}</option>,
                    )}
                  </select>
                </label>
                <label>
            Container Number
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.containerNumbers}
                    onChange={e => this.handleTextInputUpdate(e, 'containerNumbers')}
                  />
                </label>
                <label>
           Masterbill
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.masterBill}
                    onChange={e => this.handleTextInputUpdate(e, 'masterBill')}
                  />
                </label>
                <label>
           CBM
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.cbm}
                  />
                </label>
                <label>
            Net Weight
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.netWeight}
                  />
                </label>
                <label>
           Gross Weight
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.grossWeight}
                  />
                </label>
                <label>
            ETD
                  <input
                    autoFocus
                    type="date"
                    required
                    disabled={!canEdit}
                    value={order.etd && order.etd.split('T')[0]}
                    onChange={e => this.handleTextInputUpdate(e, 'etd')}
                  />
                </label>
                <label>
            COB
                  <input
                    autoFocus
                    type="date"
                    required
                    disabled={!canEdit}
                    value={order.cob && order.cob.split('T')[0]}
                    onChange={e => this.handleTextInputUpdate(e, 'cob')}
                  />
                </label>
                <label>
           ETA
                  <input
                    autoFocus
                    type="date"
                    required                
                    disabled={!canEdit}
                    value={order.eta && order.eta.split('T')[0]}
                    onChange={e => this.handleTextInputUpdate(e, 'eta')}
                  />
                </label>
                <label>
            Days Delayed
                  <input
                    autoFocus
                    type="number"
                    required
                    disabled
                    value={order.daysDelayed ?? 0}
                  />
                </label>
              </div>

              <div className="form-body">
                <label>
          Order Status
                  <select
                    value={order.orderStatusId}
                    disabled={!canEdit}
                    onChange={e => this.handleTextInputUpdate(e, 'orderStatusId')}
                  >
                    <option value=''>N/A</option>
                    {orderStatuses && orderStatuses.length > 0 && orderStatuses.map(t => 
                      <option key={t.key} 
                        value={t.key}>{t.value}</option>,
                    )}
                  </select>
                </label>
                <label>
        Progress
                  <ProgressState
                    progressStage={order.orderStatusId}
                    numberOfBoxes={6}
                  />
                </label>
                <button className="action"
                  type="button"
                  onClick={() => this.forceCloseShipment()}>
                  Force Close
                </button>
              </div>
          
            </div>
            <div className="form-container">
      
              <h4 className="header-shipping">Comments</h4>
              <div className="form-body">
              
                 <label>
                    Shipment Comments
                  <textarea
                    autoComplete="off"
                    value={order.shipmentComments}
                    onChange={e => this.handleTextInputUpdate(e, 'shipmentComments')}

                  />
      
                </label>
      
                <label>
                 
                  <div>
                    
                    <p>Selected Child Orders for Consolidated Shipment</p>
    
                    {!childOrders || childOrders.length==0 ? (
                      <p>No child orders available to display.</p> 
                    ) : (
                     
                      <Select   disabled={!orderIsParent}
                        options={options}
                        defaultValue={defaultValue} 
                        styles={customStyles}
                      >
                      </Select>
                    )}
                  </div></label>
   
                <label >
                  <div style={{ paddingLeft: '30px', paddingBottom:'12px' }}>
                    <p>Select Orders for Consolidation</p> 
                    <button
                      className="action"
                      type="button"
                      disabled={!canEdit || orderIsChild || !orderIsParent}
                      onClick={() => this.handleModal('showSelectChildOrdersModal', true)}>
                       Select
                    </button>
                  </div>
                </label>
    
                <label >
                  <p> Child Order for Consolidated Shipment</p>
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled={!canEdit || !orderIsChild || orderIsParent}
                      checked={orderIsChild} 
                      onChange={e => this.handleChildInput(e, 'orderIsChild')}

                    />
                    <div className="toggle-icon"></div>
                  </div>
                </label>
                <label>
                  <p>Parent Order</p>
                  <div className="col-md-8">
                    {orderIsChild ? (
                      <input type="button" 
                        value={(order.trackingNumberOfParentOrder!=null || order.trackingNumberOfParentOrder!='')?order.trackingNumberOfParentOrder:order.trackingNumber.slice(0, -2)} 
                      />   ) 
                      : (
                        <input
                          type="text"
                          value=""
                          className="toggle" 
                          disabled />  
                      )
                    }
                  </div>

                </label>
          
                 
              </div>

            </div>
            { this.state.showSelectChildOrdersModal && <SelectChildOrdersModal 
              cancel={() => this.handleModal('showSelectChildOrdersModal', false)}
          
             
              orderTypes = {orderTypes.slice(1)}
              orderStatuses = {orderStatuses}
              shipmentTypes = {shipmentTypes}
              marketId = {order.marketId}
              parentTrackingNumber={order.trackingNumber}
              orderId = {order.orderId}
              customerNumber={order.customerNumber}
            />}
            
        
            <div className="form-container">
              <h4 className="header-shipping">Mandatory Documents</h4>
              <div className="form-body">
                {requiredDocumentTypes && requiredDocumentTypes.length > 0 && requiredDocumentTypes.map(r =>
                  <label key={r}>
                    {documentTypes.find(d => d.documentTypeId == r)?.name}
                    <div  
                      className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={recievedRequiredDocumentTypes.includes(r)}
                        disabled
                      />
                      <div className="toggle-icon"></div>
                    </div>
                  </label>,
                )}
                <button
                  className="action"
                  type="button"
                  onClick={() => this.props.navigate(`/module/233/AOT-OrderDocuments/${order.trackingNumber}`)}>
                        View Documents
                </button>
              </div>
            </div>
            <div style={{ marginTop:'20px' }}>
              <div className="table-container">
                <div className="table-header">
                    
                  <h4 className="header-shipping">Item Details</h4>
                </div>
                <div className="table-body">
                  <table className={'table-display-mode-2'}>
                    <thead>
                      <tr>
                        <th>Supplier Name</th>
                        <th>Item Number</th>
                        <th>Item Description</th>
                        <th>Order Qty</th>
                        <th>Shipped Qty</th>
                        <th>UOM</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order && order.lines &&  order.lines.length > 0 && order.lines.map(i => 
                        <tr key={i.itemNumber}>
                          <td>{i.supplierName}</td>
                          <td>{i.itemNumber}</td>
                          <td>{i.itemDescription}</td>
                          <td>{i.orderQuantity}</td>
                          <td>{i.shipConfirmedQuantity}</td>
                          <td>{i.unitOfMeasurement}</td>
                        </tr>,
                      )}
              
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            
       
            <OrderAudit trackingNumber={order.trackingNumber} />
          </>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSaved: state.apacOrderTracker.isSaved,
    isSaving: state.apacOrderTracker.isSaving,
    isAdmin: state.apacOrderTracker.isAdmin,
    canEdit: state.apacOrderTracker.canEdit,
    orders: state.apacOrderTracker.shipmentDetail,
    isLoading: state.apacOrderTracker.isLoading,
    isLoaded: state.apacOrderTracker.isLoaded,
    errorMessage: state.apacOrderTracker.errorMessage,
    mbu: state.apacOrderTracker.mbu,
    orderStatuses: state.apacOrderTracker.orderStatuses,
    orderTypes: state.apacOrderTracker.orderTypes,
    childOrders: state.apacOrderTracker.childOrders,
    tempControls: state.apacOrderTracker.tempControls,
    shipmentTypes: state.apacOrderTracker.shipmentTypes,
    recievedRequiredDocumentTypes: state.apacOrderTracker.recievedRequiredDocumentTypes,
    requiredDocumentTypes: state.apacOrderTracker.requiredDocumentTypes,
    documentTypes: state.apacOrderTracker.documentTypes,
    tenant : state.auth.tenant,
  };
   
};

const mapDispatchToProps = dispatch => {
  return {
    getOrder: id => dispatch(apacOrderTrackerActions.getShipmentDetail(id)),
    saveOrder: data => dispatch(apacOrderTrackerActions.saveShipmentDetail(data)),
    back: () => dispatch(navigationActions.backNavigation('')),
    navigate: (path) => dispatch(navigationActions.pushNavigation(path)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShippingDetails);
