import React from 'react';

const Pagination = props => {
  const { rowCount, page, pageSize, doPaging, preventNav, isLoading } = props;

  const pageCount = Math.ceil(rowCount / pageSize);

  return (
    <div className="pagination">
      {'Showing '}
      {rowCount === 0 && '0-0 of 0'}
      {rowCount > 0 &&
        `${page * pageSize - pageSize + 1}-${
          page * pageSize < rowCount ? page * pageSize : rowCount
        } of ${rowCount}`}
      <button
        type="button"
        disabled={page === 1 || preventNav || isLoading}
        onClick={() => doPaging(page - 1)}>
        {'<'}
      </button>
      <button
        type="button"
        disabled={page >= pageCount || preventNav || isLoading}
        onClick={() => doPaging(page + 1)}>
        {'>'}
      </button>
    </div>
  );
};

export default Pagination;
