/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

function NissinHandlingTab(props) {
  const [totalHandling, setTotalHandling] = useState(0.00);
  const formDetail = props.formDetail;


  useEffect(() => {
    calculateTotalHandling();
  }, [formDetail]);

  const calculateTotalHandling = () => {
    let calculation = 0;

    calculation = (Number(formDetail.dthc) + Number(formDetail.othc) + Number(formDetail.other)
     + Number(formDetail.airShipments) + Number(formDetail.cleanTruckFee) + Number(formDetail.cmsFeeAir) + Number(formDetail.cmsFeeCourier)
     + Number(formDetail.cmsFeeLcl) + Number(formDetail.cmsFeeFcl) + Number(formDetail.cofsManagementFee) + Number(formDetail.consularFeeCertificateOfOrigin)
     + Number(formDetail.courierCharge) + Number(formDetail.documentChargeCi) + Number(formDetail.freightForwardingFeeAirFreight) + Number(formDetail.inlandFees)
     + Number(formDetail.labelingFee) + Number(formDetail.lclCertificateOfOriginPreparation) + Number(formDetail.manualDataEntryShipments)
     + Number(formDetail.multipleBlShipments) + Number(formDetail.nissinSeaToSeaTacPort) + Number(formDetail.palletizingRepalletizingFee)
     + Number(formDetail.pierPass) + Number(formDetail.proformaInvoice) + Number(formDetail.revisionRequests) + Number(formDetail.shipperLetterOfInstructions)
     + Number(formDetail.terminalGateFee) + Number(formDetail.vanningFee) + Number(formDetail.variousWarehouseCharges)
    );

    props.handleSetTotalHandling(calculation > 0 ? calculation.toFixed(2) : 0);
  };
  return (
    <>
      <div className="form-body">
        <label>
            Total Handling Cost (USD)
          <input
            disabled
            type='number'
            value={props.totalHandling}
          />
        </label>
      </div>
      <div className='form-body'>
        <label>
              DTHC (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'dthc')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.dthc}
          />
        </label>
        <label>
            OTHC (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othc')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.othc}
          />
        </label>
        <label>
             Other (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'other')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.other}
          />
        </label>
        <label>
             Air Shipments (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'airShipments')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.airShipments}
          />
        </label>
        <label> 
             Clean Truck Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'cleanTruckFee')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.cleanTruckFee}
          />
        </label>
        <label>
             CMS Fee - AIR (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'cmsFeeAir')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.cmsFeeAir}
          />
        </label>
        <label>
             CMS Fee - Courier (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'cmsFeeCourier')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.cmsFeeCourier}
          />
        </label>
        <label>
             CMS Fee - LCL (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'cmsFeeLcl')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.cmsFeeLcl}
          />
        </label>
        <label>
            CMS Fee- FCL (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'cmsFeeFcl')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.cmsFeeFcl}
          />
        </label>
        <label>
             COFS Management Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'cofsManagementFee')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.cofsManagementFee}
          />
        </label>
        <label>
             Consular Fee (Certificate of Origin) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'consularFeeCertificateOfOrigin')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.consularFeeCertificateOfOrigin}
          />
        </label>
        <label> 
             Courier Charge (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'courierCharge')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.courierCharge}
          />
        </label>
        <label>
             Document Charge (CI) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'documentChargeCi')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.documentChargeCi}
          />
        </label>
        <label>
            Freight Forwarding Fee - Air Freight (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'freightForwardingFeeAirFreight')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.freightForwardingFeeAirFreight}
          />
        </label>
        <label>
            Inland Fees (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'inlandFees')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.inlandFees}
          />
        </label>
        <label>
             Labeling Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'labelingFee')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.labelingFee}
          />
        </label>
        <label>
             LCL: Certificate of Origin Preparation (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'lclCertificateOfOriginPreparation')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.lclCertificateOfOriginPreparation}
          />
        </label>
        <label>
             Manual Data Entry Shipments (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'manualDataEntryShipments')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.manualDataEntryShipments}
          />
        </label>
        <label>
             Multiple B/L Shipments (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'multipleBlShipments')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.multipleBlShipments}
          />
        </label>
        <label>
             Nissin SEA to SEA/TAC Port (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'nissinSeaToSeaTacPort')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.nissinSeaToSeaTacPort}
          />
        </label>
        <label>
             Palletizing/Repalletizing Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'palletizingRepalletizingFee')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.palletizingRepalletizingFee}
          />
        </label>
        <label>
            Pier Pass (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'pierPass')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.pierPass}
          />
        </label>
        <label>
             Proforma Invoice (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'proformaInvoice')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.proformaInvoice}
          />
        </label>
        <label>
           Revision Requests (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'revisionRequests')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.revisionRequests}
          />
        </label>
        <label> 
           Shipper Letter of Instructions (SLI) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'shipperLetterOfInstructions')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.shipperLetterOfInstructions}
          />
        </label>
        <label>
           Terminal Gate Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'terminalGateFee')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.terminalGateFee}
          />
        </label>
        <label>
           Vanning Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'vanningFee')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.vanningFee}
          />
        </label>
        <label>
           Various warehouse charges (FA1→F81 shipments) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'variousWarehouseCharges')}
            type='number'
            disabled={props.orderIsChild}
            value={formDetail.variousWarehouseCharges}
          />
        </label>
      </div>
    </>
  );
}

export default NissinHandlingTab;
