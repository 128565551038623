/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DhlHandlingTab from './DhlHandlingTab';
import DsvHandlingTab from './DsvHandlingTab';
import ExpeditorsHandlingTab from './ExpeditorsHandlingTab';
import NissinHandlingTab from './NissinHandlingTab';
import OtherHandlingTab from './OtherHandlingTab';
import TabDocuments from './TabDocuments';
import YchHandlingTab from './YchHandlingTab';
import BrfAudit from '../BrfAudit/BrfAudit';
import SelectChildOrdersModal from '../../shared/SearchChildOrdersModal';
import Select from 'react-select';

const mapStateToProps = (state) => ({
  userModules: state.auth.modules,
});

function BookingRequestForm(props) {
  const oracleAccess = props.userModules?.includes('Confirm and Submit to Oracle');
  const [formDetail, setFormDetail] = useState({ 
  });
  const [isCreate, setIsCreate] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [_3pls, set3pls] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const [currentTab, setCurrentTab] = useState('cargo');
  const [shipModes, setShipModes] = useState({});
  const [notifyParties, setNotifyParties] = useState([]);
  const [notifyPartyId, setNotifyPartyId] = useState(0);
  const [notifyPartyDetailLoaded, setNotifyPartyDetailLoaded] = useState(false);
  const [freightTerms, setFreightTerms] = useState(null);
  const [totalFreightCost, setTotalFreightCost] = useState(0.00);
  const [totalHandling, setTotalHandling] = useState(0.00);
  const [totalInsurance, setTotalInsurance] = useState(0.00);
  const [notifyOther, setNotifyOther] = useState(false);
  const [page]  = useState(1);
  const [data, setData] = useState([]);
  const [hasTotalOrderValue, setHasTotalOrderValue] = useState(true);
  const [canConfirm, setCanConfirm] = useState(false);
  const [canSubmitToOracle, setCanSubmitToOracle] = useState(false);
  const [isSubmittedToOracle, setIsSubmittedToOracle] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [orderStatusCompleted, setOrderStatusCompleted] = useState(false);
  const [cbmCompleted, setCbmCompleted] = useState(false);
  const [weightCompleted, setWeightCompleted] = useState(false);
  const [showSelectChildOrdersModal, setShowModal] = useState(false);
  const[childOrders,setChildOrders] = useState([]);
  const[orderIsChild, setOrderIsChild] = useState(true);
  const[calculateInsurance, setCalculateInsurance] = useState(true);
  const[orderIsParent, setOrderIsParent]  = useState(false);
  const[orderCanParent, setOrderCanParent]  = useState(false);
  const [parentOrder, setParentOrder] = useState();
  const[marketId, setMarketId] = useState();
  const[orderId, setOrderId] = useState();
  const[customerNumber, setCustomerNumber] = useState();
  const[parentFreight, setParentFreight]=useState();
  const[parentHandling, setParentHandling]=useState();
  const[TotalCBM, setTotalCBM] = useState();
  const[orderIsClosed, setOrderIsClosed] = useState(false);
  const[parentSplitFreight, setparentSplitFreight]=useState(0);
  const[parentSplitHandling, setparentSplitHandling]=useState(0);
  const convertToQuerystring = (obj) => {
    const qs = Object.keys(obj)
      .map(key => `${key}=${obj[key]}`)
      .join('&');

    return qs.length === 0
      ? qs
      : `?${qs}`;
  };

   
  
  const downloadForm = () => {
    async function loadFormData(){
      const response = await fetch(`/api/omorderbookingrequest/v1/ExportBookingRequestForm${convertToQuerystring({ formId : props.match.params.id })}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (response.status == 200){
        const data = await response.blob();

        let filename = response.headers.get('content-disposition');
        let nameIndex = filename.indexOf('filename="');

        if (nameIndex > -1) {
          filename = filename.slice(nameIndex + 10);
          nameIndex = filename.indexOf('"');

          if (nameIndex > -1) {
            filename = filename.slice(0, nameIndex);
          }
        } else {
          nameIndex = filename.indexOf('filename=');
          if (nameIndex > -1) {
            filename = filename.slice(nameIndex + 9);

            nameIndex = filename.indexOf(';');

            if (nameIndex > -1) {
              filename = filename.slice(0, nameIndex);
            }
          }
        }

        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0);
      }
    }
    loadFormData();
  };


  const [cancel,setcancel] = useState();
  const [orderTypes, setOrderTypes] = useState([
    { key: 1, value: 'Ambient' },
    { key: 2, value: 'Autoship Ambient' },
    { key: 6, value: 'Direct Shipment Ambient' },
    { key: 7, value: 'Promotional' },
    { key: 9, value: 'Store Development' },
    { key: 13, value: 'Reefer' },
    { key: 14, value: 'Direct Shipment Reefer' },
    { key: 15, value: 'Autoship Reefer' },
    { key: 16, value: 'Direct Shipment Store Development' },
    { key: 24, value: 'Other' },
  ]);
  const [shipmentTypes, setShipmentTypes] = useState([
    { key: 1,value: 'Sales Order' },
    { key: 2, value: 'Purchase Order' },
    { key: 3,value: 'Internal Sales Order' },
  ]);
  const [orderStatuses, setOrderStatuses] = useState([
    {
      key: 1,
      value: 'Order Confirmed',
    },
    {
      key: 2,
      value: 'Ship Confirmed',
    },
    {
      key: 3,
      value: 'Container on Board',
    },
    {
      key: 4,
      value: 'Delay',
    },
    {
      key: 5,
      value: 'Shipment Arrival',
    },
  ]);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '21px', 
      border: state.isFocused ? '1.9 px solid green' : '1px solid #ccc', 
      boxShadow: 'none', 
      '&:hover': {
        borderColor: state.isFocused ? 'green' : '#aaa', 
      },
      outline: 'none',
      height: '40px', 
      minHeight: '40px',  
      fontSize: '16px', 
      lineHeight: '40px', 
      marginBottom: '10px',
      paddingBottom: '8% 3%',
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'space-between',
   
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingRight: '10px',
      height: '36px', 
      alignSelf: 'center',
      marginTop: '-3%',
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center', // Center text vertically
      height: '100%', // Ensure single value takes full height
      marginTop: '-3%',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none', 
    }),
    menu: (provided) => ({
      ...provided,
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '14px',
      height: '40px', 
      minHeight: '40px',
    }),
  };


  const handleChildInput=(e)=>{
    setOrderIsChild(e.target.checked);
  };
  const handleInsuranceToggle=(e)=> {
    setCalculateInsurance(e.target.checked);
    calculateTotalInsuranceCost();
  };
  const handleParentInput=(e, fieldName)=>{
    if(e.target.checked==true)  setOrderCanParent(e.target.checked); setOrderIsParent(e.target.checked);
    if(!calculateInsurance)   {setTotalInsurance(0);}
  };

  useEffect(() => {
    setParentFreight(formDetail.parentFreightTotal); 
    setTotalCBM(formDetail.totalCbm);
    setParentHandling(formDetail.parentHandlingTotal);
  }, [isLoaded, formDetail,  parentFreight, TotalCBM, parentHandling, totalHandling, parentSplitFreight, parentSplitHandling]);


  useEffect(() => {
    setOrderIsChild(orderIsChild); 
    setOrderIsParent(orderIsParent);
    setOrderCanParent(orderCanParent);
    setChildOrders(childOrders);
    setParentOrder(formDetail.trackingNumber);
    setMarketId(formDetail.marketId);
    setOrderId(formDetail.orderId);
    setCustomerNumber(formDetail.customerNumber);
    if(formDetail.removeFromConsolidatedParent==true){
      setOrderIsChild(false);
    } else {
      if (formDetail.consolidatedParentId!='undefined' ){
        if(formDetail.consolidatedParentId?.length>=10) {setOrderIsChild(true);} else {setOrderIsChild(false);}
      }
    }
    if(childOrders.length>0 ) setOrderIsParent(true); 
  
    if( !formDetail.consolidatedParentId?.length>=5 || ( !orderIsChild && !orderIsParent) )
    {setOrderCanParent(true);}
  }, [isLoaded, formDetail, childOrders, parentOrder, marketId, orderId, customerNumber, orderCanParent, orderIsParent]);

  useEffect(() => {
    (async () => {
      //is create
      if (window.location.toString().toLocaleLowerCase().indexOf('create') > -1) {
        setIsCreate(true);

        fetchIsCreateData();
      }
      else {
        fetchForm();
        loadAudit();
      }
    })();
  }, []);

  const handleModal = (show) => {
    setShowModal(show);
  };
  const checkToConfirm = () => {
    if (totalInsurance >= 0 && totalHandling >= 0 && totalFreightCost >= 0 && cbmCompleted == true && orderIsClosed==false && weightCompleted==true && orderStatusCompleted == true && isSubmittedToOracle != true && oracleAccess==true) {
      setCanConfirm(true); setCanSubmitToOracle(true);
    }
    else
    {
      setCanConfirm(false); setCanSubmitToOracle(false);
    }
  };
  const calculateTotalFreightCost = () => {
    let calculation = 0;
    if(orderIsChild){
      calculation =(Number(formDetail.totalFreight));
      setTotalFreightCost(calculation > 0 ? calculation.toFixed(2) : 0);
    }else {
      calculation = (Number(formDetail.oceanFreightCost) + Number(formDetail.airFreightCost) + Number(formDetail.bafOceanCost) + Number(formDetail.fscAirCost) + Number(formDetail.issAirCost));
      setTotalFreightCost(calculation > 0 ? calculation.toFixed(2) : 0); 
    }
    
    if (formDetail.totalCbm!=null && formDetail.totalCbm!='' && formDetail.totalCbm!='undefined'
    && formDetail.cbm!=null && formDetail.cbm!='' && formDetail.cbm!='undefined'
    ){
      calculation = (Number(formDetail.cbm)/Number(formDetail.totalCbm) * totalFreightCost);
      setparentSplitFreight(calculation > 0 ? calculation.toFixed(2) : 0); 
    }
    
    
 
    checkToConfirm(); 
    
  };

  const calculateTotalInsuranceCost = () => {
    let calculation = 0;
    if (Number(formDetail.totalOrderValue) > 0 && calculateInsurance==true){
      setHasTotalOrderValue(true);

      
       
      if (parentSplitFreight > 0 && parentSplitHandling > 0) 
      {
        calculation = ((Number(formDetail.totalOrderValue) + Number(parentSplitFreight) + (Number(parentSplitHandling) + 32)) * 0.000325);
      } 
      else if (parentSplitFreight > 0 && parentSplitHandling == 0) 
      {
        calculation = ((Number(formDetail.totalOrderValue) + Number(parentSplitFreight) + (Number(totalHandling) + 32)) * 0.000325);
      } 
      else if (parentSplitFreight == 0 && parentSplitHandling > 0) 
      {
        calculation = ((Number(formDetail.totalOrderValue) + Number(totalFreightCost) + (Number(parentSplitHandling) + 32)) * 0.000325);
      } 
      else {
        calculation = ((Number(formDetail.totalOrderValue) + Number(totalFreightCost) + (Number(totalHandling) + 32)) * 0.000325);
      }

      setTotalInsurance(calculation > 0 ? calculation.toFixed(2) : 0); checkToConfirm(); 
    }
    else if(calculateInsurance==false){
      setHasTotalOrderValue(false);
      setTotalInsurance(0);
    }

  };

  useEffect(() => {
    (async () => {

      if(!notifyPartyId && notifyParties){
        var newId = notifyParties.find(n => n.defaultOption === true)?.key;

        if(newId){
          setNotifyPartyId(newId);
        }
        
        return;
      }

      const response = await fetch(`/api/OmOrderNotifyParty/v1/GetNotifyPartyDetail${convertToQuerystring({ id: notifyPartyId })}`);

      var data = await response.json();
      if (response.status == 200){
        setNotifyPartyDetailLoaded(true);

        setFormDetail({
          ...formDetail,
          notifyPartyId: notifyPartyId,
          notifyPartyOverrideCompanyName: data.companyName,
          notifyPartyOverrideIaddress:data.notifyPartyIAddress,
          notifyPartyOverrideItelephone:data.notifyPartyITelephone,
          notifyPartyOverrideImobile:data.notifyPartyIMobile,
          notifyPartyOverrideIfax:data.notifyPartyIFax,
          notifyPartyOverrideIemail:data.notifyPartyIEmail,
          notifyPartyOverrideIattention:data.notifyPartyIAttention,
          notifyPartyOverrideIi:data.notifyPartyII,
        });
      }
    })();
  }, [notifyPartyId]);

  

  const fetchForm = () => {
    async function loadFormData(){
      const response = await fetch(`/api/omorderbookingrequest/v1/GetBookingRequestFormDetail${convertToQuerystring({ formId : props.match.params.id })}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
  
      if (response.status == 200){
        const data = await response.json();
        //format decimals on load to two fixed places.
        const rateCardObj = {};
        for (const [key, value] of Object.entries(data.rateCardModel)) {
          if (value != null){
            rateCardObj[key] = value?.toFixed(2);
          }
          else{
            rateCardObj[key] = '0.00';
          }
        }

        setChildOrders(data.childOrders);

        setFormDetail({
          ...data.formDetail,
          ...rateCardObj,
          ...data.childOrders,
          eta: data.formDetail.eta?.split('T')[0],
          etd: data.formDetail.etd?.split('T')[0],
          parentOrder:data.formDetail.trackingNumber,
        });

       
        
         
        
        setFreightTerms(data.freightTerms);
        setShipModes(data.shipModes);
        setNotifyParties(data.notifyParties);
        setNotifyOther((data.formDetail && data.formDetail.notifyPartyOther) || false);
        setNotifyPartyId(data.formDetail && data.formDetail.notifyPartyId);
        setIsLoaded(true);
        setIsCompleted(data.completed);
        if(data.formDetail.cbm!=null && data.formDetail.cbm!=0) setCbmCompleted(true);
        if(data.formDetail.grossWeight!=null && data.formDetail.grossWeight!=0) setWeightCompleted(true);
        if(data.formDetail.orderStatusId==6) setOrderIsClosed(true);
        setParentOrder(data.formDetail.trackingNumber);
        if (data.formDetail.completed == true) setOrderStatusCompleted(true);
        if (data.formDetail.isSubmittedToOracle == true) { setCanConfirm(false); setCanSubmitToOracle(false); setIsSubmittedToOracle(true); } 
        setCalculateInsurance(data.formDetail.calculateInsurance);
      }
    }
    loadFormData();
  };


  useEffect(() => {
    calculateTotalFreightCost();
    calculateTotalInsuranceCost();
  }, [isLoaded, formDetail, calculateInsurance, oracleAccess, totalHandling]);


  const isNissin = formDetail._3pl?.toLowerCase() == 'nissin';
  const isDhl = formDetail._3pl?.toLowerCase() == 'dhl';
  const isYchFrdc = formDetail._3pl?.toLowerCase() == 'ych-frdc';
  const isDsv = formDetail._3pl?.toLowerCase() == 'dsv';
  const isYch = formDetail._3pl?.toLowerCase() == 'ych';
  const isExpeditors = formDetail._3pl?.toLowerCase() == 'expeditors';
  const isOther = formDetail._3pl?.toLowerCase() == 'other';

    
  const fetchIsCreateData = () => {
    async function loadFormData(){
      const response = await fetch('/api/omorderbookingrequest/v1/GetCreateFormDetail', {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      setErrorMessage(response.errorMessage);

      if (response.status == 200){
        const data = await response.json();
        set3pls(data._3pls);
        const newFormDetail = {
          ...formDetail, 
          isCreate: true,
        };
        setFormDetail(newFormDetail);
      }
    }
    loadFormData();
  };

  const handleSetTotalHandling = (value) => {
    if(orderIsChild){
      let calculation =(Number(formDetail.totalHandling));
      setTotalHandling(calculation > 0 ? calculation.toFixed(2) : 0);

    }else{
      setTotalHandling(value);
      if (formDetail.totalCbm!=null && formDetail.totalCbm!='' && formDetail.totalCbm!='undefined'
          && formDetail.cbm!=null && formDetail.cbm!='' && formDetail.cbm!='undefined'
      ){
        let  calculation = (Number(formDetail.cbm)/Number(formDetail.totalCbm) * value);
        setparentSplitHandling(calculation > 0 ? calculation.toFixed(2) : 0); 
      }
    }
    calculateTotalInsuranceCost();
  };
  const handleTextInputUpdate = (e, fieldName) => {
 
    const inputType = e.target.type;
    let inputValue = e.target.value;

    if (inputType === 'number' && !isNumericStringInputPositiveValue(inputValue)) {
      inputValue = '0';
    }
    
    setFormDetail({ ...formDetail, [fieldName] : inputValue });
  
  };

  const loadAudit = async() => {
    const response = await fetch(`/api/omorderbookingrequest/v1/GetBrfAuditTrail?brfId=${props.match.params.id}&page=${page}&pageSize=25`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    if (response.status == 200){
      
      const stuff = await response.json();
      setData(stuff.data);
    }
  };

  const isNumericStringInputPositiveValue = (ElementValue) => {
    const numericValue = Number(ElementValue);
    return !isNaN(numericValue) && numericValue >= 0;
  };

  const handleCheckInputUpdate = (e, fieldName) => {
    setFormDetail({ ...formDetail, [fieldName] : e.target.checked });
  };

  const handleChangeTab = (tab) => {   
    setCurrentTab(tab);
  };

  const options = childOrders.map(order => ({
    value: order.key,
    label: order.value,
  }));
  const defaultValue = options.length > 0 ? options[0] : null;

  const confirmForm = () => {
    loadFormData(); async function loadFormData() {
      setIsConfirming(true);
      const response = await fetch('/api/omorderbookingrequest/v1/SaveBookingRequestForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          ...formDetail,
          totalFreight: totalFreightCost,
          totalHandling,
          totalFHI : totalInsurance,
          notifyPartyId,
          confirm:true,
          notifyPartyOther: notifyOther == true ? true : false,
          calculateInsurance: (formDetail.calculateInsurance!=calculateInsurance) ? calculateInsurance:null,
        }),
      });

      const data = await response.json();
  
      if (response.status == 400){
        setErrorMessage(data);
      }

      if (response.status == 200){
        setIsConfirmed(true);

        setTimeout(() => {
          setIsConfirmed(false);
        }, 3000);

        fetchForm();
        loadAudit();

        if (isCreate && data != null){
          window.location.replace(`/module/315/BRF-Edit/${data}`);
        }
      }
      fetchForm();
      setIsConfirming(false); 
    }
  };

  const submitForm = () => {
    loadFormData(); async function loadFormData() {
      setIsSubmitting(true);
      const response = await fetch('/api/omorderbookingrequest/v1/SaveBookingRequestForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          ...formDetail,
          totalFreight: totalFreightCost,
          totalHandling,
          totalFHI : totalInsurance,
          notifyPartyId,
          confirm:false,
          submitToOracle: canSubmitToOracle,
          notifyPartyOther: notifyOther == true ? true : false,
          calculateInsurance: (formDetail.calculateInsurance!=calculateInsurance) ? calculateInsurance:null,
        }),
      });

      const data = await response.json();
  
      if (response.status == 400){
        setErrorMessage(data);
      }

      if (response.status == 200){
        setIsSubmitted(true);

        setTimeout(() => {
          setIsSubmitted(false);
        }, 3000);

        fetchForm();
        loadAudit();

        if (isCreate && data != null){
          window.location.replace(`/module/315/BRF-Edit/${data}`);
        }
      }
      fetchForm();
      setIsSubmitting(false); 
    }
  };
    
  const saveForm = (complete) => {
    if (isCreate && formDetail._3pl == ''){
      setErrorMessage('Please select a 3pl.');
    }
    else{
      loadFormData(complete);
    }
    async function loadFormData(complete){
      complete? setIsCompleting(true) : setIsSaving(true);
      const response = await fetch('/api/omorderbookingrequest/v1/SaveBookingRequestForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          ...formDetail,
          totalFreight: totalFreightCost,
          totalHandling,
          totalFHI : totalInsurance,
          notifyPartyId,
          complete,
          confirm:false,
          notifyPartyOther: notifyOther == true ? true : false,
          removeFromConsolidatedParent: !orderIsChild,
          shipmentsConsolidated: orderIsParent,
          calculateInsurance: (formDetail.calculateInsurance!=calculateInsurance) ? calculateInsurance:null,
        }),
      });

      const data = await response.json();
  
      if (response.status == 400){
        setErrorMessage(data);
      }

      if (response.status == 200){
        complete? setIsCompleted(true) : setIsSaved(true);

        setTimeout(() => {
          complete? setIsCompleted(false) : setIsSaved(false);
        }, 3000);

        fetchForm();
        loadAudit();

        if (isCreate && data != null){
          window.location.replace(`/module/315/BRF-Edit/${data}`);
        }
      }
      fetchForm();
      complete? setIsCompleting(false) : setIsSaving(false);
    }
  };

  const enabledMarkets = [49, 55, 47, 68];
  const marketCanSubmitToOracle = enabledMarkets.includes(formDetail?.marketId);

  return (
    <div id="bookingRequestForm">
      <div  className="edit-feedback-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className="icon icon-form"></div>
              <h2>{isCreate ? 'Create Booking Request Form' : 'Edit Booking Request Form'}</h2>
            </div>
            <div
              style={{ display:'flex' }}>
              {!isCreate &&
              <div className="group-details accordion download-container summary"
                style={{ marginRight: '25px' } }
              >
                <div className="accordion-body horizontal-flex order-fields">
                  <div>
                    <button
                      className="action export"
                      type="button"
                      title="Export"
                      onClick={() => downloadForm()}
                    >
                      <div
                        className="icon icon-download-light"
                        alt="Export"
                      />
                    </button>
                  </div>
                </div>
              </div>
              }
              <div className="group-details accordion download-container summary"
                style={{ marginRight: '18px' } }
              >
                <div className="accordion-body horizontal-flex order-fields">
                  <div>
                    <button
                      type="button"
                      className="action"
                      disabled={isCreate ? (!formDetail.trackingNumber && !formDetail.starbucksOrderNumber) : isSubmittedToOracle}
                      onClick={() => saveForm(false)}>
                      {isSaving ? 'Saving...' : isSaved ? 'Saved' : 'Save'}
                    </button>
                  </div>
                  {isCreate ? null :
                    <div>
                      <button
                        type="button"
                        className="action"
                        disabled={isSubmittedToOracle ? true : false}
                        onClick={() => saveForm(true)}>
                        {isCompleting ? 'Completing...' : isCompleted ? 'Completed' : 'Complete'}
                      </button>
                    </div>}
                </div>
              </div>

            
              {isCreate || !marketCanSubmitToOracle ? null :
                <div className="group-details accordion download-container summary"
                  style={{ marginRight: '18px' } }
                >
                  <div className="accordion-body horizontal-flex order-fields">
                    <div>
                      <button
                        type="button"
                        className="action"
                        onClick={() => confirmForm(true)}
                        disabled={canConfirm ? false : true}>
                        {isConfirming ? 'Confirming...' : isConfirmed ? 'Confirmed' : 'Confirm'}
                      </button></div>
                    {isCreate ? null : <div>
                      <button
                        type="button"
                        className="action"
                        onClick={() => submitForm(true)}
                        disabled = {canSubmitToOracle ? false : true}>
                        {isSubmitting ? 'Submitting...' : isSubmitted ? 'Submitted' : 'Submit'}
                      </button>
                    </div>}
                  </div>
                
                </div> }
                
            </div>
          </div>
        </div>

        <form className="form-container"
          noValidate
          onSubmit={e => false}>
          <div className="form-header">
            <h3>Order Detail</h3>
          </div>
          <div className="form-body">
            {isCreate &&
            <>
              <div>Start by entering Starbucks Order Number and/or the Sales Order Number linked to the order.</div>
            </>
            }
            {!isCreate &&
            <>
              <label>
                  Status
                <input
                  className='uneditable'
                  disabled
                  value={formDetail.completed ? 'Completed' : 'Pending'}
                />
              </label>
              <label>
                  Shipper
                <input
                  className='uneditable'
                  disabled
                  value={'Starbucks Corporation, 2401 Utah Avenue South, Seattle, WA  98134, United States of America'}
                />
              </label>
              <label>
               3PL
                <input
                  className='uneditable'
                  disabled
                  value={formDetail._3pl ?? 'N/A'}
                />
              </label>
              <label>
               Consignee
                <input
                  className='uneditable'
                  disabled
                  value={formDetail.customerName ?? 'N/A'}
                />
              </label>
              <label>
                  Sample Order{' '}
                <div className="toggle-wrapper">
                  <input
                    type="checkbox"
                    className="toggle"
                    checked={formDetail.sampleOrder}
                    onChange={(e) => handleCheckInputUpdate(e, 'sampleOrder')}
                  />
                  <div className="toggle-icon"></div>
                </div>
              </label>
            </>
            }
          </div>
          {!isCreate &&
          <div className='form-body'>
            {formDetail.sampleOrder &&
            <label>
                  Notify Other{' '}
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  checked={notifyOther ?? false}
                  onChange={(e) => setNotifyOther(e.target.checked)}
                />
                <div className="toggle-icon"></div>
              </div>
            </label>
            }
            <label>
                  Notify Party{' '}
              <select
                value={notifyPartyId}
                onChange={e => setNotifyPartyId(e.target.value)}
              >
                <option value=''>Select a party...</option>
                {notifyParties && notifyParties.length && notifyParties.map(m => 
                  <option key={m.key}
                    value={m.key}>{m.value}</option>,
                )
                }
              </select>
            </label>
            {(notifyPartyDetailLoaded || notifyOther) && ( 
              <>  
                <label>
                Notify Party
                Company Name
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideCompanyName')}
                    value={formDetail.notifyPartyOverrideCompanyName}
                  />
                </label> 
                <label>
                 Notify Party I
                  Address
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideIaddress')}
                    value={formDetail.notifyPartyOverrideIaddress}
                  />
                </label> 
                <label>
                 Notify Party I
                Telephone
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideItelephone')}
                    value={formDetail.notifyPartyOverrideItelephone}
                  />
                </label> 
                <label>
                 Notify Party I
                  Mobile
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideImobile')}
                    value={formDetail.notifyPartyOverrideImobile}
                  />
                </label> 
                <label>
                 Notify Party I
                 Fax
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideIfax')}
                    value={formDetail.notifyPartyOverrideIfax}
                  />
                </label> 
                <label>
                 Notify Party I
                  Email
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideIemail')}
                    value={formDetail.notifyPartyOverrideIemail}
                  />
                </label> 
                <label>
                 Notify Party I
                  Attention
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideIattention')}
                    value={formDetail.notifyPartyOverrideIattention}
                  />
                </label> 
                <label>
                 Notify Party II
                  {' '}
                  <input
                    disabled={!notifyOther}
                    onChange={e => handleTextInputUpdate(e, 'notifyPartyOverrideIi')}
                    value={formDetail.notifyPartyOverrideIi}
                  />
                </label>  
              </>
            )}
          </div>
          }
          <div className='form-body'>
            {isCreate &&
            <label>
                   3PL *
              <select
                value={formDetail._3pl}
                onChange={e => handleTextInputUpdate(e, '_3pl')}
              >
                <option value=''></option>
                {_3pls && _3pls.length && _3pls.map(m => 
                  <option key={m.key}
                    value={m.value}>{m.value}</option>,
                )
                }
              </select>
            </label>
            }
            <label>
                 Starbucks Reference Number
              <input
                className={`${!isCreate ? 'uneditable' : ''}`}
                disabled={!isCreate}
                onChange={e => handleTextInputUpdate(e, 'trackingNumber')}
                value={formDetail.trackingNumber ?? 'N/A'}
              />
            </label>
            <label>
                Sales Order Number
              <input
                className={`${!isCreate ? 'uneditable' : ''}`}
                disabled={!isCreate}
                onChange={e => handleTextInputUpdate(e, 'starbucksOrderNumber')}
                value={formDetail.starbucksOrderNumber ?? 'N/A'}
              />
            </label>
            {!isCreate && (
              <label>
                Total Order Value
                <input
                  className={'uneditable'}
                  disabled
                  value={formDetail.totalOrderValue ?? 'N/A'}
                />
              </label>
            )}
            <div className='form-body'>
              <div style={{ color: 'red' }}>{errorMessage}</div>
            </div>
            {!isCreate &&
            <>
              <label>
                 Delivery ID Number
                <input
                  className='uneditable'
                  disabled
                  value={formDetail.deliveryId ?? 'N/A'} 
                />
              </label>
              <label>
                  Supplier PO/Release No
                <input
                  className='uneditable'
                  disabled
                  value={formDetail.supplierReleaseNo ?? 'N/A'}
                />
              </label>
              <label>
                 Ready To Ship Date
                <input
                  className='uneditable'
                  disabled
                  value={(formDetail.rtsDate && new Date(formDetail.rtsDate).toLocaleDateString()) ?? 'N/A'}
                />
              </label>
              <label>
                Request ETA (Promise Date)
                <input
                  className='uneditable'
                  disabled
                  value={(formDetail.promiseDate && new Date(formDetail.promiseDate).toLocaleDateString()) ?? 'N/A'}
                />
              </label>
              <label>
                Carrier
                <input
                  onChange={e => handleTextInputUpdate(e, 'carrier')}
                  type="text"
                  value={formDetail.carrier}
                />
              </label>
              <label>
                Port Of Loading
                <input
                  onChange={e => handleTextInputUpdate(e, 'portOfLoading')}
                  type="text"
                  value={formDetail.portOfLoading}
                />
              </label>
              <label>
                Port Of Discharge
                <input
                  onChange={e => handleTextInputUpdate(e, 'portOfDischarge')}
                  type="text"
                  value={formDetail.portOfDischarge}
                />
              </label>
              <label>
                Place Of Delivery
                <input
                  onChange={e => handleTextInputUpdate(e, 'placeOfDelivery')}
                  type="text"
                  value={formDetail.placeOfDelivery}
                />
              </label>
              <label>
                BL Number
                <input
                  onChange={e => handleTextInputUpdate(e, 'blnumber')}
                  type="text"
                  value={formDetail.blnumber}
                />
              </label>
              <label>
                ETD
                <input
                  onChange={e => handleTextInputUpdate(e, 'etd')}
                  type="date"
                  value={formDetail.etd}
                />
              </label>
              <label>
                ETA
                <input
                  onChange={e => handleTextInputUpdate(e, 'eta')}
                  type="date"
                  value={formDetail.eta}
                />
              </label>
              <label>
                Feeder Vessel Name
                <input
                  onChange={e => handleTextInputUpdate(e, 'feederVesselName')}
                  type="text"
                  value={formDetail.feederVesselName}
                />
              </label>
              <label>
                Feeder Voyage Number
                <input
                  onChange={e => handleTextInputUpdate(e, 'feederVoyageNumber')}
                  type="text"
                  value={formDetail.feederVoyageNumber}
                />
              </label>
              <label>
                Mother Vessel Name
                <input
                  onChange={e => handleTextInputUpdate(e, 'motherVesselName')}
                  type="text"
                  value={formDetail.motherVesselName}
                />
              </label>
              <label>
                Mother Voyage Number
                <input
                  onChange={e => handleTextInputUpdate(e, 'motherVoyageNumber')}
                  type="text"
                  value={formDetail.motherVoyageNumber}
                />
              </label>
              <label>
                Supplier Document Charge
                <input
                  onChange={e => handleTextInputUpdate(e, 'supplierDocumentCharge')}
                  type="number"
                  value={formDetail.supplierDocumentCharge}
                />
              </label>
              <label>
                Freight Term
                <select
                  value={formDetail.freightTermId}
                  onChange={e => handleTextInputUpdate(e, 'freightTermId')}
                >
                  <option value=''>Select a freight term...</option>
                  {freightTerms && freightTerms.length && freightTerms.map(m => 
                    <option key={m.key}
                      value={m.key}>{m.value}</option>,
                  )
                  }
                </select>
              </label>
              <label>
                    Container Numbers (comma seperated)
                <input
                  onChange={e => handleTextInputUpdate(e, 'containerNumbers')}
                  type="text"
                  value={formDetail.containerNumbers}
                />
              </label>

             
            

                 <label>
                         Shipment Comments
                  <textarea
                    value={formDetail.shipmentComments}
                    onChange={e => handleTextInputUpdate(e, 'shipmentComments')}
                    autoComplete="off"
                  />
                </label>
                
              

              <label className="comments split"  >
                <p>Parent Order for Consolidated Shipment</p> 
                <div className="toggle-wrapper">
                  <input
                    type="checkbox"
                    id="parentToggle"
                    className="toggle"
                    checked={orderIsParent}
                    disabled={ (formDetail.childOrders?.length>0 || formDetail.consolidatedParentId?.length>=5 ||  (!orderIsChild && !orderCanParent) || isSubmittedToOracle) ? true : false }
                    onChange={e => handleParentInput(e, 'orderIsParent')}
                  />
                  <div className="toggle-icon"></div>
                </div>
              </label>
              <label>
               
                <div>
                    
                  <p>Selected Child Orders for Consolidated Shipment</p>
                  
                  {!childOrders || childOrders.length==0 ? (
                    <p>No child orders available to display.</p> 
                  ) : (
                     
                    <Select   disabled={!orderIsParent}
                      options={options}
                      defaultValue={defaultValue} 
                      styles={customStyles}
                    >
                    </Select>
                  )}
                </div>
              </label>
              <label >
                <div style={{ paddingLeft: '30px', paddingBottom:'12px' }}>
                  <p>Select Orders for Consolidation</p> 
                  <button
                    className="action"
                    type="button"
                    disabled={orderIsChild || !orderIsParent || isSubmittedToOracle}
                    onClick={() => handleModal(true)}>
                       Select
                  </button>
                </div>
              </label>
              <label className="comments">
                <p> Child Order for Consolidated Shipment</p>
                <div className="toggle-wrapper">
                  <input
                    type="checkbox"
                    className="toggle"
                    disabled={orderIsParent || !orderIsChild}
                    checked={orderIsChild} 
                    onChange={handleChildInput}

                  />
                  <div className="toggle-icon"></div>
                </div>
              </label>
              <label>
                <p>Parent Order</p>
                <div className="col-md-8">
                  {orderIsChild ? (
                    <input type="button" 
                      value={(formDetail.consolidatedParentId!=null || formDetail.consolidatedParentId!='')?formDetail.consolidatedParentId:formDetail.trackingNumber.slice(0, -2)} 
                      onClick={() => {
                        if (formDetail.parentId) {
                          window.open(`/module/315/BRF-Edit/${formDetail.parentId}`, '_blank');
                        }
                      }}
                    />   ) 
                    : (
                      <input
                        type="text"
                        value=""
                        className="toggle" 
                        disabled />  
                    )
                  }
                </div>

              </label>
              { showSelectChildOrdersModal && <SelectChildOrdersModal 
                cancel={() => handleModal( false)}
          
                showSelectChildOrdersModal= {showSelectChildOrdersModal}
                orderTypes = {orderTypes}
                orderStatuses = {orderStatuses}
                shipmentTypes = {shipmentTypes}
                marketId = {marketId}
                parentTrackingNumber={parentOrder}
                orderId = {orderId}
                customerNumber={customerNumber}

              />
              }
            
            </>
            }
          </div>
        </form>
        
        {isLoaded && !isCreate &&
        <>
          <div className="tab-container">
            <div
              className={`tab-button ${
                currentTab === 'cargo' ? 'selected' : ''
              }`}
              onClick={() => handleChangeTab('cargo')}>
              Cargo
              <br />
              Detail
            </div>
            <div
              className={`tab-button ${
                currentTab === 'freight' ? 'selected' : ''
              }`}
              onClick={() => handleChangeTab('freight')}>
              Freight
            </div>
            <div
              className={`tab-button ${
                currentTab === 'handling' ? 'selected' : ''
              }`}
              onClick={() => handleChangeTab('handling')}>
              Handling
            </div>
            <div
              className={`tab-button ${
                currentTab === 'insurance' ? 'selected' : ''
              }`}
              onClick={() => handleChangeTab('insurance')}>
              FHI Result
            </div>
            <div
              className={`tab-button ${
                currentTab === 'documents' ? 'selected' : ''
              }`}
              onClick={() => handleChangeTab('documents')}>
              Documents
            </div>
          </div>
          <form className="form-container"
            noValidate
            style={{ display: currentTab === 'cargo' ? 'block' : 'none' }}
            onSubmit={e => false}>
            <div className="form-header">
              <h3>Cargo Detail</h3>
            </div>
            
            <div className="form-body">
              <label>
                  Gross Weight (Kg)
                <input
                  onChange={e => handleTextInputUpdate(e, 'grossWeight')}
                  placeholder='e.g: 6000'
                  type="number"
                  value={formDetail.grossWeight}
                />
              </label>
              <label>
                 Net Weight (Kg)
                <input
                  onChange={e => handleTextInputUpdate(e, 'netWeight')}
                  placeholder='e.g: 4000'
                  type="number"
                  value={formDetail.netWeight}
                />
              </label>
              <label>
                  CBM{' '}
                <input
                  onChange={e => handleTextInputUpdate(e, 'cbm')}
                  placeholder='e.g: 14'
                  type="number"
                  value={formDetail.cbm}
                />
              </label>
              <label>
                  Piece{' '}
                <input
                  onChange={e => handleTextInputUpdate(e, 'piece')}
                  placeholder='e.g: 20'
                  type="number"
                  value={formDetail.piece}
                />
              </label>
              <label>
                  Carton{' '}
                <input
                  onChange={e => handleTextInputUpdate(e, 'carton')}
                  placeholder='e.g: 60'
                  type="number"
                  value={formDetail.carton}
                />
              </label>
              <label>
                 Pallets
                <input
                  onChange={e => handleTextInputUpdate(e, 'pallet')}
                  type="number"
                  value={formDetail.pallet}
                />
              </label>
              <label>
                 Ship Mode
                <select
                  value={formDetail.shipModeId}
                  onChange={e => handleTextInputUpdate(e, 'shipModeId')}
                >
                  <option value=''>Select a ship mode...</option>
                  {shipModes && shipModes.length && shipModes.map(m => 
                    <option key={m.key}
                      value={m.key}>{m.value}</option>,
                  )
                  }
                </select>
              </label>
              <label>
                 Commodity
                <input
                  onChange={e => handleTextInputUpdate(e, 'commodity')}
                  placeholder='e.g: Merchandise'
                  type="text"
                  maxLength={60}
                  value={formDetail.commodity}
                />
              </label>
              <label>
                 Seal Number
                <input
                  onChange={e => handleTextInputUpdate(e, 'sealNumber')}
                  placeholder=''
                  type="text"
                  maxLength={200}
                  value={formDetail.sealNumber}
                />
              </label>
              <label>
                  Number of container/size: 20&apos;GP
                <input
                  onChange={e => handleTextInputUpdate(e, 'noContainer20Gp')}
                  type="number"
                  maxLength={5}
                  value={formDetail.noContainer20Gp} />
              </label><label>
                    Number of container/size: 40&apos;GP
                <input
                  onChange={e => handleTextInputUpdate(e, 'noContainer40Gp')}
                  type="number"
                  maxLength={5}
                  value={formDetail.noContainer40Gp} />
              </label><label>
                    Number of container/size: 40&apos;HC
                <input
                  onChange={e => handleTextInputUpdate(e, 'noContainer40Hc')}
                  type="number"
                  maxLength={5}
                  value={formDetail.noContainer40Hc} />
              </label><label>
                    Number of container/size: 20&apos;RF
                <input
                  onChange={e => handleTextInputUpdate(e, 'noContainer20Rf')}
                  type="number"
                  maxLength={5}
                  value={formDetail.noContainer20Rf} />
              </label><label>
                    Number of container/size: 40&apos;RF
                <input
                  onChange={e => handleTextInputUpdate(e, 'noContainer40Rf')}
                  type="number"
                  maxLength={5}
                  value={formDetail.noContainer40Rf} />
              </label>
            </div>
          </form>
          <form className="form-container"
            noValidate
            style={{ display: currentTab === 'freight' ? 'block' : 'none' }}
            onSubmit={e => false}>
            <div className="form-header">
              <h3>Freight</h3>
            </div>
            <div className="form-body">
              <label>
                  Total Freight Cost (USD)
                <input
                  disabled
                  type='number'
                  value={totalFreightCost}
                />
              </label>
            </div>
            <div className="form-body">
              <label>
                  Ocean Freight Cost (USD)
                <input
                  disabled={orderIsChild}
                  onChange={e => handleTextInputUpdate(e, 'oceanFreightCost')}
                  type='number'
                  value={formDetail.oceanFreightCost}
                />
              </label>
              <label>
                  Air Freight Cost (USD)
                <input
                  disabled={orderIsChild}
                  onChange={e => handleTextInputUpdate(e, 'airFreightCost')}
                  type='number'
                  value={formDetail.airFreightCost}
                />
              </label>
              <label>
                  BAF (Ocean) Cost (USD)
                <input
                  disabled={orderIsChild}
                  onChange={e => handleTextInputUpdate(e, 'bafOceanCost')}
                  type='number'
                  value={formDetail.bafOceanCost}
                />
              </label>
              <label>
                  FSC (Air) Cost (USD)
                <input
                  disabled={orderIsChild}
                  onChange={e => handleTextInputUpdate(e, 'fscAirCost')}
                  type='number'
                  value={formDetail.fscAirCost}
                />
              </label>
              <label>
                  ISS (Air) Cost (USD)
                <input
                  disabled={orderIsChild}
                  onChange={e => handleTextInputUpdate(e, 'issAirCost')}
                  type='number'
                  value={formDetail.issAirCost}
                />
              </label>
            </div>
          </form>
          <form className="form-container"
            noValidate
            style={{ display: currentTab === 'handling' ? 'block' : 'none' }}
            onSubmit={e => false}>
            <div className="form-header">
              <h3>Handling</h3>
            </div>
            {isNissin && 
            <NissinHandlingTab
              handleTextInputUpdate={handleTextInputUpdate}
              formDetail={formDetail}
              totalHandling={totalHandling}
              orderIsChild = {orderIsChild}
              handleSetTotalHandling={(v) => handleSetTotalHandling(v)}
            />
            }
            {isDhl || isYchFrdc &&
            <DhlHandlingTab
              handleTextInputUpdate={handleTextInputUpdate}
              formDetail={formDetail}
              totalHandling={totalHandling}
              orderIsChild = {orderIsChild}
              handleSetTotalHandling={(v) => handleSetTotalHandling(v)}
            />
            
            }
            {isDsv &&
            <DsvHandlingTab
              handleTextInputUpdate={handleTextInputUpdate}
              formDetail={formDetail}
              totalHandling={totalHandling}
              orderIsChild = {orderIsChild}
              handleSetTotalHandling={(v) => handleSetTotalHandling(v)}
            />
            }
            {isExpeditors &&
            <ExpeditorsHandlingTab
              handleTextInputUpdate={handleTextInputUpdate}
              formDetail={formDetail}
              totalHandling={totalHandling}
              orderIsChild = {orderIsChild}
              handleSetTotalHandling={(v) => handleSetTotalHandling(v)}
            />
            }
            {isYch &&
            <YchHandlingTab
              handleTextInputUpdate={handleTextInputUpdate}
              formDetail={formDetail}
              totalHandling={totalHandling}
              orderIsChild = {orderIsChild}
              handleSetTotalHandling={(v) => handleSetTotalHandling(v)}
            />
            }
            {isOther &&
            <OtherHandlingTab
              handleTextInputUpdate={handleTextInputUpdate}
              formDetail={formDetail}
              totalHandling={totalHandling}
              orderIsChild = {orderIsChild}
              handleSetTotalHandling={(v) => handleSetTotalHandling(v)}
            />
            }
          </form>
          <form className="form-container"
            noValidate
            style={{ display: currentTab === 'insurance' ? 'block' : 'none' }}
            onSubmit={e => false}>
            <div className="form-header">
              <h3>FHI Result</h3>
            </div>
            <div className="form-body">
            
              <label>
                  Total Order Value (USD)
                <input
                  disabled
                  type='number'
                  value={formDetail.totalOrderValue}
                />
              </label>   
              <label>
                   Total Freight Cost (USD)
                <input
                  disabled
                  type='number'
                  value={parentSplitFreight > 0  ? parentSplitFreight : totalFreightCost}
                />
              </label> 
              <label>
                  Total Handling Cost (USD)
                <input
                  disabled
                  type='number'
                  value={parentSplitHandling > 0  ? Number(parentSplitHandling) +32.5 : Number(totalHandling) + 32.5}
                 
                />
              </label>     
              <label>
                  Total Insurance Cost (USD)
                <input
                  disabled
                  type='number'
                  value={totalInsurance}
                />
                <p style={{ display: hasTotalOrderValue == true ? 'block' : 'none' }} >
               (Total Order Value + Total Freight Cost + Total Handling Cost) x 0.000325</p>
              </label>
              <label>
                <p> Calculate Insurance </p>
                <div className="toggle-wrapper">
                  <input
                    type="checkbox"
                    className="toggle"
                    checked={calculateInsurance} 
                    onChange={handleInsuranceToggle}

                  />
                  <div className="toggle-icon"></div>
                </div>
              </label>
             
              
            </div>
           
          </form>
          <form className="form-container"
            noValidate
            style={{ display: currentTab === 'documents' ? 'block' : 'none' }}
            onSubmit={e => false}>
            <div className="form-header">
              <h3>Documents</h3>
            </div>
            <TabDocuments
              formId={props.match.params.id}
            />
          </form>
          {data && 
          <div style={{ marginTop: '20px' }}>
            <BrfAudit data={data} />
          </div>
          }
        </>
        }
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(BookingRequestForm); 
