/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

function YchHandlingTab(props) {  
  const [totalHandling, setTotalHandling] = useState(0.00);
  const formDetail = props.formDetail;

  useEffect(() => {
    calculateTotalHandling();
  }, [formDetail]);

  const calculateTotalHandling = () => {
    let calculation = 0;
    let noContainer20Gp = formDetail.noContainer20Gp;
    let noContainer20Rf = formDetail.noContainer20Rf;
    let noContainer40Gp = formDetail.noContainer40Gp;
    let noContainer40Hc = formDetail.noContainer40Hc;
    let noContainer40Rf = formDetail.noContainer40Rf;

    calculation = (Number(formDetail.dthc) + Number(formDetail.othc) + Number(formDetail.other)
    + Number(formDetail.othersFumigationNoContainer20Gp * noContainer20Gp) + Number(formDetail.othersFumigationNoContainer20Rf * noContainer20Rf)
    + Number(formDetail.othersFumigationNoContainer40Gp * noContainer40Gp) + Number(formDetail.othersFumigationNoContainer40Hc * noContainer40Hc)
    + Number(formDetail.othersFumigationNoContainer40Rf * noContainer40Rf) + Number(formDetail.certificateOfOrigin)
    + Number(formDetail.lclLclCharges) + Number(formDetail.lclPortWharfage) + Number(formDetail.lclTerminalHandlingCharge)
    + Number(formDetail.lclTruckingDry) + Number(formDetail.lclTruckingReefer) + Number(formDetail.lclPermitFee) + Number(formDetail.lclFreightManagementFee)
    + Number(formDetail.lclFormAdepreparation) + Number(formDetail.fclEtcCharges) + Number(formDetail.fclWharfageCharges) + Number(formDetail.fclPermitFee)
    + Number(formDetail.fclDepotContainerManagementSystemNoContainer20Gp * noContainer20Gp) + Number(formDetail.fclDepotContainerManagementSystemNoContainer20Rf * noContainer20Rf) 
    + Number(formDetail.fclDepotContainerManagementSystemNoContainer40Gp * noContainer40Gp) + Number(formDetail.fclDepotContainerManagementSystemNoContainer40Hc * noContainer40Hc)
    + Number(formDetail.fclDepotContainerManagementSystemNoContainer40Rf * noContainer40Rf) + Number(formDetail.fclDepotHandling) + Number(formDetail.fclTruckingDryNoContainer20Gp * noContainer20Gp)
    + Number(formDetail.fclTruckingDryNoContainer20Rf * noContainer20Rf) + Number(formDetail.fclTruckingDryNoContainer40Gp * noContainer40Gp) + Number(formDetail.fclTruckingDryNoContainer40Hc * noContainer40Hc)
    + Number(formDetail.fclTruckingDryNoContainer40Rf * noContainer40Rf) + Number(formDetail.fclTruckingReeferNoContainer20Gp * noContainer20Gp) + Number(formDetail.fclTruckingReeferNoContainer20Rf * noContainer20Rf)
    + Number(formDetail.fclTruckingReeferNoContainer40Gp * noContainer40Gp) + Number(formDetail.fclTruckingReeferNoContainer40Hc * noContainer40Hc) + Number(formDetail.fclTruckingReeferNoContainer40Rf * noContainer40Rf)
    + Number(formDetail.fclFreightManagementFee) + Number(formDetail.fclBlfee) + Number(formDetail.fclSurrenderBlfee) + Number(formDetail.fclDoFee) + Number(formDetail.fclAgencyFee)
    + Number(formDetail.fclLabourFee) + Number(formDetail.fclFormAdepreparation) + Number(formDetail.airPermitFee) + Number(formDetail.airFreightManagementFee) + + Number(formDetail.airFormAdepreparation) + Number(formDetail.reeferTransportationMax3PalletsCrossDocks)
    + Number(formDetail.reeferTransportationMoreThan3PalletsCrossDocks) + Number(formDetail.nonReeferTransportationMax6Pallets) + Number(formDetail.nonReeferTransportationMoreThan6Pallets) + Number(formDetail.documentFee) + Number(formDetail.vasLabels)
    + Number(formDetail.phytocert)
    ); 

    props.handleSetTotalHandling(calculation > 0 ? calculation.toFixed(2) : 0);
  };
  return (
    <>
      <div className="form-body">
        <label>
            Total Handling Cost (USD)
          <input
            disabled
            type='number'
            value={props.totalHandling}
          />
        </label>
      </div>
      <div className='form-body'>
        <label>
              DTHC (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'dthc')}
            type='number'
            value={formDetail.dthc}
            disabled={props.orderIsChild}

          />
        </label>
        <label>
            OTHC (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othc')}
            type='number'
            value={formDetail.othc}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Other (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'other')}
            type='number'
            value={formDetail.other}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Others - Fumigation (Container 20GP) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othersFumigationNoContainer20Gp')}
            type='number'
            value={formDetail.othersFumigationNoContainer20Gp}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Others - Fumigation (Container 20RF) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othersFumigationNoContainer20Rf')}
            type='number'
            value={formDetail.othersFumigationNoContainer20Rf}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Others - Fumigation (Container 40GP) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othersFumigationNoContainer40Gp')}
            type='number'
            value={formDetail.othersFumigationNoContainer40Gp}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Others - Fumigation (Container 40HC) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othersFumigationNoContainer40Hc')}
            type='number'
            value={formDetail.othersFumigationNoContainer40Hc}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Others - Fumigation (Container 40RF) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othersFumigationNoContainer40Rf')}
            type='number'
            value={formDetail.othersFumigationNoContainer40Rf}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Certificate of Origin (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'certificateOfOrigin')}
            type='number'
            value={formDetail.certificateOfOrigin}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             LCL: LCL Charges (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'lclLclCharges')}
            type='number'
            value={formDetail.lclLclCharges}
            disabled={props.orderIsChild}
          />
        </label>
        <label> 
             LCL: Port Wharfage (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'lclPortWharfage')}
            type='number'
            value={formDetail.lclPortWharfage}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             LCL: Terminal Handling Charge  (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'lclTerminalHandlingCharge')}
            type='number'
            value={formDetail.lclTerminalHandlingCharge}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             LCL: Trucking (Dry) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'lclTruckingDry')}
            type='number'
            value={formDetail.lclTruckingDry}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             LCL: Trucking (Reefer) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'lclTruckingReefer')}
            type='number'
            value={formDetail.lclTruckingReefer}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             LCL: Permit Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'lclPermitFee')}
            type='number'
            value={formDetail.lclPermitFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             LCL: Freight Management Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'lclFreightManagementFee')}
            type='number'
            value={formDetail.lclFreightManagementFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             LCL: Form A / D / E Preparation (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'lclFormAdepreparation')}
            type='number'
            value={formDetail.lclFormAdepreparation}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: ETC charges (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclEtcCharges')}
            type='number'
            value={formDetail.fclEtcCharges}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: Wharfage Charges (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclWharfageCharges')}
            type='number'
            value={formDetail.fclWharfageCharges}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: Permit Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclPermitFee')}
            type='number'
            value={formDetail.fclPermitFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL:  Depot Container Management System (CMS) (Container 20GP) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclDepotContainerManagementSystemNoContainer20Gp')}
            type='number'
            value={formDetail.fclDepotContainerManagementSystemNoContainer20Gp}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL:  Depot Container Management System (CMS) (Container 20RF) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclDepotContainerManagementSystemNoContainer20Rf')}
            type='number'
            value={formDetail.fclDepotContainerManagementSystemNoContainer20Rf}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL:  Depot Container Management System (CMS) (Container 40GP) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclDepotContainerManagementSystemNoContainer40Gp')}
            type='number'
            value={formDetail.fclDepotContainerManagementSystemNoContainer40Gp}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL:  Depot Container Management System (CMS) (Container 40HC) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclDepotContainerManagementSystemNoContainer40Hc')}
            type='number'
            value={formDetail.fclDepotContainerManagementSystemNoContainer40Hc}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL:  Depot Container Management System (CMS) (Container 40RF) (USD)
          <input 
            onChange={e => props.handleTextInputUpdate(e, 'fclDepotContainerManagementSystemNoContainer40Rf')}
            type='number'
            value={formDetail.fclDepotContainerManagementSystemNoContainer40Rf}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: Depot Handling (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclDepotHandling')}
            type='number'
            value={formDetail.fclDepotHandling}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: Trucking (Dry) (Container 20GP) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclTruckingDryNoContainer20Gp')}
            type='number'
            value={formDetail.fclTruckingDryNoContainer20Gp}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: Trucking (Dry) (Container 20RF) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclTruckingDryNoContainer20Rf')}
            type='number'
            value={formDetail.fclTruckingDryNoContainer20Rf}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: Trucking (Dry) (Container 40GP) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclTruckingDryNoContainer40Gp')}
            type='number'
            value={formDetail.fclTruckingDryNoContainer40Gp}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: Trucking (Dry) (Container 40HC) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclTruckingDryNoContainer40Hc')}
            type='number'
            value={formDetail.fclTruckingDryNoContainer40Hc}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: Trucking (Dry) (Container 40RF) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclTruckingDryNoContainer40Rf')}
            type='number'
            value={formDetail.fclTruckingDryNoContainer40Rf}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: Trucking (Reefer) (Container 20GP) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclTruckingReeferNoContainer20Gp')}
            type='number'
            value={formDetail.fclTruckingReeferNoContainer20Gp}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
                 FCL: Trucking (Reefer) (Container 20RF) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclTruckingReeferNoContainer20Rf')}
            type='number'
            value={formDetail.fclTruckingReeferNoContainer20Rf}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
               FCL: Trucking (Reefer) (Container 40GP) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclTruckingReeferNoContainer40Gp')}
            type='number'
            value={formDetail.fclTruckingReeferNoContainer40Gp}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
                FCL: Trucking (Reefer) (Container 40HC) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclTruckingReeferNoContainer40Hc')}
            type='number'
            value={formDetail.fclTruckingReeferNoContainer40Hc}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
                 FCL: Trucking (Reefer) (Container 40RF) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclTruckingReeferNoContainer40Rf')}
            type='number'
            value={formDetail.fclTruckingReeferNoContainer40Rf}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: Freight Management Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclFreightManagementFee')}
            type='number'
            value={formDetail.fclFreightManagementFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: B/L Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclBlfee')}
            type='number'
            value={formDetail.fclBlfee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: Surrender B/L Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclSurrenderBlfee')}
            type='number'
            value={formDetail.fclSurrenderBlfee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: DO Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclDoFee')}
            type='number'
            value={formDetail.fclDoFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: Agency Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclAgencyFee')}
            type='number'
            value={formDetail.fclAgencyFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: Labour Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclLabourFee')}
            type='number'
            value={formDetail.fclLabourFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             FCL: Form A / D / E Preparation (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fclFormAdepreparation')}
            type='number'
            value={formDetail.fclFormAdepreparation}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             AIR: Permit Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'airPermitFee')}
            type='number'
            value={formDetail.airPermitFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             AIR: Freight Management Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'airFreightManagementFee')}
            type='number'
            value={formDetail.airFreightManagementFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             AIR: Form A / D / E Preparation (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'airFormAdepreparation')}
            type='number'
            value={formDetail.airFormAdepreparation}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Reefer Transportation 
            (Max 3 Pallets)-Cross Docks (AB, OLAM, AALST) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'reeferTransportationMax3PalletsCrossDocks')}
            type='number'
            value={formDetail.reeferTransportationMax3PalletsCrossDocks}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Reefer Transportation 
        (Additional Pallet More Than 3 Pallets)-Cross-Docks (AB, OLAM, AALST) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'reeferTransportationMoreThan3PalletsCrossDocks')}
            type='number'
            value={formDetail.reeferTransportationMoreThan3PalletsCrossDocks}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Non-Reefer Transportation
            (Max 6 pallets)-Cross Docks (AB, OLAM, AALST) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'nonReeferTransportationMax6Pallets')}
            type='number'
            value={formDetail.nonReeferTransportationMax6Pallets}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Non-Reefer Transportation
            (Additional Pallet More Than 6 Pallets)-Cross Docks (AB, OLAM, AALST) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'nonReeferTransportationMoreThan6Pallets')}
            type='number'
            value={formDetail.nonReeferTransportationMoreThan6Pallets}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Document Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'documentFee')}
            type='number'
            value={formDetail.documentFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             VAS Labels (For MBU which requires VAS) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'vasLabels')}
            type='number'
            value={formDetail.vasLabels}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Phytocert (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'phytocert')}
            type='number'
            value={formDetail.phytocert}
            disabled={props.orderIsChild}
          />
        </label>
      </div>
    </>
  );
}

export default YchHandlingTab;
