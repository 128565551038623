/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

function DsvHandlingTab(props) {
  const [totalHandling, setTotalHandling] = useState(0.00);
  const formDetail = props.formDetail;

  useEffect(() => {
    calculateTotalHandling();
  }, [formDetail]);

  const calculateTotalHandling = () => {
    let calculation = 0;
    let noContainer20Gp = formDetail.noContainer20Gp;
    let noContainer20Rf = formDetail.noContainer20Rf;
    let noContainer40Gp = formDetail.noContainer40Gp;
    let noContainer40Hc = formDetail.noContainer40Hc;
    let noContainer40Rf = formDetail.noContainer40Rf;
    let airOrShipFee = formDetail.omManagementFeePerShipmentLclAir;

    calculation = (Number(formDetail.omManagementFeeNoContainer20Gp * noContainer20Gp) + Number(formDetail.omManagementFeeNoContainer20Rf * noContainer20Rf)
     + Number(formDetail.omManagementFeeNoContainer40Gp * noContainer40Gp)  + Number(formDetail.omManagementFeeNoContainer40Hc * noContainer40Hc) 
     + Number(formDetail.omManagementFeeNoContainer40Rf * noContainer40Rf) + Number(formDetail.dthc) + Number(formDetail.othc) + Number(formDetail.other)
     + Number(formDetail.airShipmentOriginFee) + Number(formDetail.bunkerAdjustmentFactorValue) + Number(formDetail.documentFee) + Number(formDetail.originDrayFclDw)
     + Number(formDetail.originLclPickupDw)
    );

    if (formDetail.shipModeId == 2 ||  formDetail.shipModeId == 3){
      calculation += Number(airOrShipFee);
    }

    props.handleSetTotalHandling(calculation > 0 ? calculation.toFixed(2) : 0);
  };
  return (
    <>
      <div className="form-body">
        <label>
            Total Handling Cost (USD)
          <input
            disabled
            type='number'
            value={props.totalHandling}
          />
        </label>
      </div>
      <div className='form-body'>
        <label>
              OM Management Fee (Container 20Gp) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'omManagementFeeNoContainer20Gp')}
            type='number'
            value={formDetail.omManagementFeeNoContainer20Gp}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              OM Management Fee (Container 20Rf) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'omManagementFeeNoContainer20Rf')}
            type='number'
            value={formDetail.omManagementFeeNoContainer20Rf}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              OM Management Fee (Container 40Gp) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'omManagementFeeNoContainer40Gp')}
            type='number'
            value={formDetail.omManagementFeeNoContainer40Gp}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              OM Management Fee (Container 40Hc) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'omManagementFeeNoContainer40Hc')}
            type='number'
            value={formDetail.omManagementFeeNoContainer40Hc}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              OM Management Fee (Container 40Rf) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'omManagementFeeNoContainer40Rf')}
            type='number'
            value={formDetail.omManagementFeeNoContainer40Rf}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              OM Management Fee (Per Shipment of LCL and Air) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'omManagementFeePerShipmentLclAir')}
            type='number'
            value={formDetail.omManagementFeePerShipmentLclAir}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
              DTHC (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'dthc')}
            type='number'
            value={formDetail.dthc}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
            OTHC (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othc')}
            type='number'
            value={formDetail.othc}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Other (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'other')}
            type='number'
            value={formDetail.other}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Air Shipment Origin Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'airShipmentOriginFee')}
            type='number'
            value={formDetail.airShipmentOriginFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Bunker Adjustment Factor Value (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'bunkerAdjustmentFactorValue')}
            type='number'
            value={formDetail.bunkerAdjustmentFactorValue}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Document Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'documentFee')}
            type='number'
            value={formDetail.documentFee}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Origin Dray FCL-DW (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'originDrayFclDw')}
            type='number'
            value={formDetail.originDrayFclDw}
            disabled={props.orderIsChild}
          />
        </label>
        <label>
             Origin LCL Pickup- DW (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'originLclPickupDw')}
            type='number'
            value={formDetail.originLclPickupDw}
            disabled={props.orderIsChild}
          />
        </label>
      </div>
    </>
  );
}

export default DsvHandlingTab;
