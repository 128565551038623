import {
  APAC_ORDERTRACKER_CLEARSTATE,
  APAC_ORDERTRACKER_SHIPMENT_REQUEST,
  APAC_ORDERTRACKER_SHIPMENT_SUCCESS,
  APAC_ORDERTRACKER_SHIPMENT_FAILURE,
  APAC_SHIPMENTLOGUPLOAD_REQUEST,
  APAC_SHIPMENTLOGUPLOAD_SUCCESS,
  APAC_SHIPMENTLOGUPLOAD_FAILURE,
  APAC_SAVEORDER_REQUEST,
  APAC_SAVEORDER_SUCCESS,
  APAC_SAVEORDER_FAILURE,
  APAC_ORDERTRACKER_GETORDERS_REQUEST,
  APAC_ORDERTRACKER_GETORDERS_SUCCESS,
  APAC_ORDERTRACKER_GETORDERS_FAILURE,
  APAC_ORDERTRACKER_INPROGRESSSUMMARY_REQUEST,
  APAC_ORDERTRACKER_INPROGRESSSUMMARY_SUCCESS,
  APAC_ORDERTRACKER_INPROGRESSSUMMARY_FAILURE,
  APAC_BULKORDERSTATUSUPDATE_REQUEST,
  APAC_BULKORDERSTATUSUPDATE_SUCCESS,
  APAC_BULKORDERSTATUSUPDATE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

const apacOrderTrackerReducer = (state = initialState.orderTrackerState, action) => {
  switch (action.type) {
  case APAC_ORDERTRACKER_CLEARSTATE:
    return {
      ...initialState.orderTrackerState,
      customerStores: [...state.customerStores],
    };
  case APAC_ORDERTRACKER_INPROGRESSSUMMARY_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      error: '',
      orders: [],
      shipmentTypes: [],
      orderTypes: [],
      orderStatuses: [],
      mbus: [],
      carriers: [],
      fulfilments: [],
      shipMethods: [],
      fiscals: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  case APAC_ORDERTRACKER_INPROGRESSSUMMARY_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      orders: action.payload.orders.data,
      pageNumber: action.payload.orders.pageNumber,
      pageSize: action.payload.orders.pageSize,
      totalRecords: action.payload.orders.totalRecords,
      mbus: action.payload.mbus,
      shipmentTypes: action.payload.shipmentTypes,
      orderTypes: action.payload.orderTypes,
      carriers: action.payload.carriers,
      orderStatuses: action.payload.orderStatuses,
      shipMethods: action.payload.shipMethods,
      fulfilments: action.payload.fulfilments,
      fiscals: action.payload.fiscals,
    };
  case APAC_ORDERTRACKER_INPROGRESSSUMMARY_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: action.payload.message,
      orders: [],
      shipmentTypes: [],
      orderTypes: [],
      orderStatuses: [],
      mbus: [],
      carriers: [],
      fulfilments: [],
      shipMethods: [],
      fiscals: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  case APAC_ORDERTRACKER_GETORDERS_REQUEST:
    return {
      ...state,
      ordersLoading: true,
      orders: undefined,
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  case APAC_ORDERTRACKER_GETORDERS_SUCCESS:
    return {
      ...state,
      ordersLoading: false,
      orders: action.payload.orders.data,
      pageNumber: action.payload.orders.pageNumber,
      pageSize: action.payload.orders.pageSize,
      totalRecords: action.payload.orders.totalRecords,
      orderStatuses: action.payload.orderStatuses,
      orderTypes: action.payload.orderTypes,
      shipmentTypes: action.payload.shipmentTypes,
      mbus: action.payload.mbus,
    };
  case APAC_ORDERTRACKER_GETORDERS_FAILURE:
    return {
      ...state,
      ordersLoading: false,
      error: action.payload.message,
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  case APAC_ORDERTRACKER_SHIPMENT_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      isUploading: false,
      isSaving: false,
      isSaved: false,
      shipmentDetail: undefined,
    };
  case APAC_ORDERTRACKER_SHIPMENT_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      shipmentDetail: action.payload.shipmentDetail.orderRows,
      isAdmin: action.payload.isAdmin,
      canEdit: action.payload.canEdit,
      orderTypes: action.payload.orderTypes,
      childOrders: action.payload.childOrders,
      orderStatuses: action.payload.orderStatuses,
      mbu: action.payload.mbu,
      tempControls: action.payload.tempControls,
      shipmentTypes: action.payload.shipmentTypes,
      requiredDocumentTypes: action.payload.requiredDocumentTypes,
      recievedRequiredDocumentTypes: action.payload.recievedRequiredDocumentTypes,
      documentTypes: action.payload.documentTypes,
    };
  case APAC_ORDERTRACKER_SHIPMENT_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      shipmentDetail: undefined,
      error: action.payload.message,
    };
  case APAC_SHIPMENTLOGUPLOAD_REQUEST: {
    return {
      ...state,
      isUploading: true,
      isLoaded: false,
      errorMessage: '',
    };
  }
  case APAC_SHIPMENTLOGUPLOAD_SUCCESS: {
    return {
      ...state,
      isUploading: false,
      isLoaded: true,
      errorMessage: '',
    };
  }
  case APAC_SHIPMENTLOGUPLOAD_FAILURE: {
    return {
      ...state,
      isUploading: false,
      isLoaded: false,
      errorMessage: action.payload.message,
    };
  }
  case APAC_SAVEORDER_REQUEST: {
    return {
      ...state,
      isSaving: true,
      isSaved: false,
      errorMessage: '',
    };
  }
  case APAC_SAVEORDER_SUCCESS: {
    return {
      ...state,
      isSaving: false,
      isSaved: true,
      errorMessage: '',
    };
  }
  case APAC_SAVEORDER_FAILURE: {
    return {
      ...state,
      isSaving: false,
      isSaved: false,
      errorMessage: action.payload,
    };
  }
  case APAC_BULKORDERSTATUSUPDATE_REQUEST: {
    return {
      ...state,
      bulkUpdateLoading: true,
      errorMessage: '',
    };
  }
  case APAC_BULKORDERSTATUSUPDATE_SUCCESS: {
    return {
      ...state,
      bulkUpdateLoading: false,
      errorMessage: '',
    };
  }
  case APAC_BULKORDERSTATUSUPDATE_FAILURE: {
    return {
      ...state,
      bulkUpdateLoading: false,
      errorMessage: action.payload,
    };
  }
  default:
    return state;
  }
};

export default apacOrderTrackerReducer;
